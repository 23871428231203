import { Component, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppIconService } from './core/services/app-icon.service';
import { IntegracaoWhatsappService } from './core/services/integracao-whatsapp.service';
import { SubjectService } from './core/services/subject.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  chatPolos: boolean = false;
  versao: string = 'v2.124';

  constructor(
    public translate: TranslateService,
    private titleService: Title,
    private renderer: Renderer2,
    private router: Router,
    private appIconService: AppIconService,
    private integracaoWhatsappService: IntegracaoWhatsappService,
    private subjectService: SubjectService,
  ) {
    translate.addLangs(['en', 'es', 'it', 'ru', 'de']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it|es|ru|de/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    const temaLink = document.createElement('link');
    temaLink.id = 'tema-stylesheet-primeng';
    temaLink.rel = 'stylesheet';
    document.head.appendChild(temaLink);

    let hostname = window.location.hostname;
    if (hostname.includes("chatpolos")) {
      this.chatPolos = true;
    }

    if (this.chatPolos) {
      this.titleService.setTitle(`ChatPolos - ${this.versao}`);
    } else {
      this.titleService.setTitle(`BkChat - ${this.versao}`);
    }

    this.router.events.subscribe(() => this.setThemeBasedOnUrl());


    setInterval(() => {
      this.integracaoWhatsappService.index().subscribe(json => {
        json = json.filter(instancias => { return instancias.ativo == true });
        let offline = json.filter(instancias => { return instancias.online == false });
        localStorage.setItem('countInstanciasOffline', offline.length);
        this.subjectService.atualizaInstanciasOffline();
      })
    }, 60000);

  }

  ngAfterViewInit(): void {
    let element = document.getElementById('btn-export-departamento');

  }

  setThemeBasedOnUrl() {
    let hostname = window.location.hostname;
    if (hostname.includes("chatpolos")) {
      this.loadTheme('chat-polos-theme');
      this.appIconService.setFavicon("assets/images/IconOnly_Transparent_NoBuffer.png");

    }


  }

  loadTheme(theme: string) {
    this.renderer.removeClass(document.body, 'chat-polos-theme');
    this.renderer.addClass(document.body, theme);
  }
}
