<div class="card" style="height: calc(100vh - 100px);">



  <p-table #dt1 [value]="canalAtendimentoList" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
    [globalFilterFields]="['nome', 'whatsapp', 'token', 'identificador', 'ativo']" [rowHover]="true"
    [loading]="loading">

    <ng-template pTemplate="caption">
      <p-toolbar>
        <div class="p-toolbar-group-left">
          <div class="p-input-icon-left p-fluid p-field p-mr-2">
            <input pInputText type="text" class="p-inputtext-sm ml-4"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
          </div>
        </div>
      </p-toolbar>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th>Nome</th>
        <th>Whatsapp</th>
        <th>Token</th>
        <th>Identificador</th>
        <th>Habilitada</th>
        <th pSortableColumn="online">Status <p-sortIcon field="online"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr class="row-test">
        <td>{{ item.nome }}</td>
        <td>{{ item.whatsapp }}</td>
        <td>
          <span class="truncate-text" [title]="item.token">{{ item.token }}</span>
        </td>
        <td>
          <span class="truncate-text" [title]="item.identificador">{{ item.identificador }}</span>
        </td>
        <td>{{ item.ativo ? 'Sim' : 'Não' }}</td>
        <td><i class="pi pi-whatsapp" [style]="styleInstanciaOnline(item)" (click)="show(item)"></i></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td>
          Total de {{canalAtendimentoList ? canalAtendimentoList.length : 0}} registros.
        </td>
      </tr>
    </ng-template>
  </p-table>


  <perfect-scrollbar *ngIf="false" class="chat-message-list">
    <div class="col-lg-12 row d-flex">
      <div class="col-lg-2" *ngFor="let canal of canalAtendimentoList">
        <div *ngIf="canal" class="card bg-pattern">
          <div class="card-body">
            <div class="text-center">
              <img src="assets/images/whatsapp.png" alt="logo" class="avatar-xl rounded-circle mb-3">
              <h6 class="mb-1 font-15">{{canal.nome}} <a href="javascript: void(0);" (click)="show(canal)"
                  class="btn btn-info btn-xs waves-effect waves-light me-1"><i class="mdi mdi-pencil"></i></a>
              </h6>
            </div>

            <div class="text-center">
              <span *ngIf="canal.online == true"
                class="badge badge-soft-success me-1 rounded-pill font-15">Online</span>
              <span *ngIf="canal.online !== true" class="badge badge-soft-danger me-1 rounded-pill">Offline</span>
            </div>


          </div>
        </div>
      </div>
    </div>

  </perfect-scrollbar>
</div>




<p-dialog header="Instancia" [(visible)]="modal" [style]="{width: '100vw', height: '100vh'}">
  <app-instancia-whatsapp (onClose)="modal = false" [instancia]="instanciaSelected"></app-instancia-whatsapp>

  <ng-template pTemplate="footer">


  </ng-template>
</p-dialog>
