import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { LightboxModule } from 'ngx-lightbox';
 

import {DatePipe} from '@angular/common';

// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { TranslateModule } from '@ngx-translate/core';

import {DialogModule} from 'primeng/dialog';

import {ButtonModule} from 'primeng/button';

import {ProgressSpinnerModule} from 'primeng/progressspinner';

 
import { CdTimerModule } from 'angular-cd-timer'; 

import {AutoCompleteModule} from 'primeng/autocomplete';

import {TableModule} from 'primeng/table';

import {GMapModule} from 'primeng/gmap';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import {BadgeModule} from 'primeng/badge'; 
import { ImageModule } from 'primeng/image';
import {TabViewModule} from 'primeng/tabview';
import {ToolbarModule} from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { AccessPermissionsComponent } from './access-permissions/access-permissions.component';
import { TreeTableModule } from "primeng/treetable";
import { ParameterizationComponent } from './parameterization/parameterization.component';
import { InputSwitchModule } from "primeng/inputswitch";
import { CalendarModule } from 'primeng/calendar';
import { PasswordModule } from 'primeng/password';
import { CampoPersonalizadoPessoaComponent } from './campo-personalizado-pessoa/campo-personalizado-pessoa.component';
import { PlanilhaEstacioComponent } from './planilha-estacio/planilha-estacio.component';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { CampoPersonalizadoProdutoComponent } from './campo-personalizado-produto/campo-personalizado-produto.component';
import { AutomacaoEstacioComponent } from './automacao-estacio/automacao-estacio.component';
import { AcessosEstacioComponent } from './acessos-estacio/acessos-estacio.component';
import { AssistenteConexaoPolosComponent } from './assistente-conexao-polos/assistente-conexao-polos.component';
import { BaseConhecimentoIaComponent } from './base-conhecimento-ia/base-conhecimento-ia.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';



@NgModule({
  declarations: [
    SettingsPageComponent, 
    AccessPermissionsComponent, 
    ParameterizationComponent, 
    CampoPersonalizadoPessoaComponent, 
    CampoPersonalizadoProdutoComponent, 
    PlanilhaEstacioComponent, 
    AutomacaoEstacioComponent, 
    AcessosEstacioComponent, 
    AssistenteConexaoPolosComponent,
    BaseConhecimentoIaComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    LightboxModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    FormsModule,
    ReactiveFormsModule, 
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    SimplebarAngularModule,
    PickerModule,
    DialogModule,
    ButtonModule,
    CdTimerModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    TableModule,
    GMapModule,
    ConfirmDialogModule,
    DropdownModule,
    BadgeModule,
    ImageModule,
    TabViewModule,
    ToolbarModule,
    InputTextModule,
    TreeTableModule,
    InputSwitchModule,
    CalendarModule,
    PasswordModule,
    PanelModule,
    FileUploadModule,
    MultiSelectModule,
    InputTextareaModule,
    CheckboxModule
  ],
  exports: [
    SettingsPageComponent, 
    AccessPermissionsComponent, 
    ParameterizationComponent, 
    PlanilhaEstacioComponent, 
    AcessosEstacioComponent, 
    AssistenteConexaoPolosComponent,
    BaseConhecimentoIaComponent
  
  ]
})
export class SettingsPageModule { }
