<div *ngIf="page == 'form'" class="row p-fluid container-div">

  <form [formGroup]="form" class="col-md-7">

    <div class="p-field-checkbox col-sm-12 col-md-12 col-lg-12 mb-4">
      <p-checkbox formControlName="ativo" binary="true"></p-checkbox>
      <label>Ativo</label>
    </div>

    <div class="col-md-12">
      <label>Descrição</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="descricao" />
    </div> 

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Departamento</label>
      <p-multiSelect appendTo="body" [options]="dropdownDepartamentos" formControlName="departamentos"
        optionLabel="label" class="p-inputtext-sm">
      </p-multiSelect>
    </div>

    <div class="col-md-12">
      <label>Instrução de atendimento</label>
      <textarea rows="10" cols="30" pInputTextarea formControlName="instrucao"></textarea>
    </div>

    <!-- <div class="col-md-12">
      <label>Informe o contexto</label>
      <textarea rows="30" cols="30" pInputTextarea formControlName="conteudo"></textarea>
    </div> -->
  </form>

  <div class="col-md-5"> 

    <p-table #dt1 [value]="form.get('arquivos').value" [loading]="loadingFiles">

      <ng-template pTemplate="caption">
        <div class="row p-mt-2">

          <div class="col-md-12 mb-2">
            <input pInputText type="text" class="p-inputtext-sm ml-4"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
          </div>

          <div class="col-md-4">
            <p-fileUpload #fubauto mode="basic" [customUpload]="true" name="demo[]" accept=".txt, .pdf"
              (uploadHandler)="myUploader($event)" [auto]="true" chooseLabel="Selecione o arquivo"></p-fileUpload>
          </div>

          <div class="col-md-8">
            <div class="p-inputgroup">
              <input type="text" pInputText placeholder="insira um link" #linkInput>
              <button type="button" pButton pRipple icon="pi pi-plus" styleClass="p-button-danger"
                (click)="downloadAndUploadFile(linkInput.value)"></button>
            </div>
          </div>
        </div>

        <!-- <p-toolbar>
          <div class="p-toolbar-group-left">
            <div class="p-input-icon-left p-fluid p-field p-mr-2">
              <input pInputText type="text" class="p-inputtext-sm ml-4"
                (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
            </div>
          </div>

          <div class="p-toolbar-group-right d-flex">
            <p-fileUpload #fubauto mode="basic" [customUpload]="true" name="demo[]" accept=".xls, .xlsx, .pdf"
              maxFileSize="1000000" (uploadHandler)="myUploader($event)" [auto]="true"
              chooseLabel="Selecione o arquivo"></p-fileUpload>
          </div>
        </p-toolbar> -->
      </ng-template>


      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Nome</th>
          <th>Tamanho</th>
          <th>Data do upload</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item?.original_name}}</td>
          <td>{{item?.sizeFormat}}</td>
          <td>{{item?.created_at | date:'dd/MM/yyyy'}}</td>
          <td>
            <i *ngIf="item?.baseConhecimentoId" class="pi pi-eye mr-2" style="cursor: pointer; margin-right: 5px;"
              (click)="showContexto(item)"></i>
            <i class="pi pi-times" style="cursor: pointer;" (click)="removeFile(item)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="col-auto">
    <button type="button" class="btn btn-danger btn mr-2" (click)="page = 'view'" label="Cancelar"><i
        class="pi pi-times"></i></button>
  </div>

  <div class="col-auto">
    <button *ngIf="!loadingSave" type="button" class="btn btn-success btn mr-2" (click)="save()" label="Salvar"><i
        class="pi pi-save"></i></button>

    <p-progressSpinner *ngIf="loadingSave" [style]="{width: '40px', height: '40px', padding: '10px'}"
      styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>
</div>


<p-table *ngIf="page == 'view'" [value]="basesList" styleClass="p-datatable-sm p-datatable-gridlines"
  [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>
      </div>

      <div class="p-toolbar-group-right d-flex">
        <button type="button" class="btn btn-primary btn mr-2" (click)="add()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Descrição</th>
      <th>Criado por</th>
      <th>Data</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{item.descricao}}</td>
      <td>{{item.usuario.name}}</td>
      <td>{{item.created_at | date:'dd/MM/yyyy'}}</td>
      <td> <button type="button" class="btn btn-primary btn mr-2" (click)="show(item.id)" ngbTooltip="Adicionar"><i
            class="pi pi-pencil"></i></button></td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Departamento" [(visible)]="modalContexto" [style]="{width: '35vw'}">

  <textarea rows="30" cols="100" pInputTextarea [(ngModel)]="contextoArquivo"></textarea>

  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modalContexto = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>
  </ng-template>
</p-dialog>