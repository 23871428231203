import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'arquivo');
  }

  uploadFile(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/upload-file-simple`, body);
  }

  upload(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/upload`, body);
  }

  uploadMulti(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/upload-multi`, body);
  }
  downloadFileToS3(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/download`, body);
  }
}
