<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>
      </div>

      <div class="p-toolbar-group-right d-flex">

        <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;"
          (click)="exportExcel()" ngbTooltip="Exportar para excel"><i class="pi pi-file-export"></i></button>

        <button type="button" class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr>
      <th class="th-codigo">Código</th>
      <th>Nome</th>
      <th>Data upload</th>
      <th>Link</th>
      <th>Envios</th>
      <th>Deletar</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td class="th-codigo">{{item.id}}</td>
      <td>{{item.nome}}</td>
      <td>{{item.created_at | date:'dd/MM/yyyy'}}</td>
      <td>
        <a href="{{urlS3}}/{{item.arquivo.path}}" target="_blank">Link</a>
      </td>
      <td>
        <button type="button" class="btn btn-primary btn mr-2" (click)="getHistoricoEnvio(item)"
          ngbTooltip="Visualizar historico"><i class="pi pi-whatsapp"></i></button>
      </td>
      <td>
        <button type="button" class="btn btn-danger btn mr-2" (click)="delete(item)" ngbTooltip="Deletar planilha"><i
            class="pi pi-times"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Adicionar planilha" [(visible)]="displayModal" [modal]="true" [style]="{width: '60vw'}"
  [draggable]="false">

  <div class="font-13 box-shadow-custom">
    <div class="col-sm-12 mb-2">
      <label for="inputtext">Nome</label>
      <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText [(ngModel)]="nomeArquivo">
    </div>

    <p-fileUpload chooseLabel="Selecione o arquivo" name="myFile" (uploadHandler)="onUpload($event)"
      maxFileSize="50000000" [auto]="true" [customUpload]="true">
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
      </ng-template>
    </p-fileUpload>
  </div>



  <ng-template pTemplate="footer">
    <div>
      <button (click)="displayModal=false" label="Fechar" type="button"
        class="btn btn-danger waves-effect waves-light me-1">Fechar</button>

      <button [disabled]="form?.invalid" (click)="save()" label="Salvar" type="button"
        class="btn btn-success waves-effect waves-light me-1">Salvar</button>
    </div>
  </ng-template>
</p-dialog>


<p-dialog header="Histórico de mensagens enviadas" [(visible)]="modalHistoricoMensagem" [style]="{width: '80vw'}">

  <p-table [value]="dataSourceHistorico" [loading]="loadingHistorico">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-auto">
          <label>Enviados</label>
          <input disabled pInputText [(ngModel)]="countEnviados" />
        </div>

        <div class="col-auto">
          <label>Erros</label>
          <input disabled pInputText [(ngModel)]="countErros" />
        </div>

        <div class="col-auto">
          <label>Pendentes</label>
          <input disabled pInputText [(ngModel)]="countPendentes" />
        </div>

        <div class="col-auto">
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
            class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>

        <div class="col-auto">
          <button type="button" pButton pRipple icon="pi pi-times" (click)="cancelEnvios()" class="p-button-danger mr-2"
            pTooltip="Cancelar envios pendentes" tooltipPosition="bottom"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th>Origem</th>
        <th>Destino</th>
        <th>Retorno</th>
        <th>Status</th>
        <th>Conteudo</th>
        <th>Codigo departamento</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [ngStyle]="colorGrid(item)">
        <td>{{item.origem}}</td>
        <td>{{item.destino}}</td>
        <td>{{item.retorno}}</td>
        <td>{{item.statusLabel}}</td>
        <td>{{item.conteudo}}</td>
        <td>{{item.departamento_id}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td>
          Total de {{dataSourceHistorico ? dataSourceHistorico.length : 0 }} registros.
        </td>
      </tr>
    </ng-template>
  </p-table>


  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
