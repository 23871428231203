<!-- <div class="p-toolbar p-component" style="background: #f8f9fa !important; border: #f8f9fa; padding: 15px;">

</div> -->


<nav class="navbar navbar-light bg-light">
  <div class="p-fluid row">
    <!-- <div class="col-auto">
            <label for="lastname2">Usuário</label>
            <p-multiSelect appendTo="body" class="p-inputtext-sm" [options]="dropdownUsuarios"
                [(ngModel)]="selectedUsuario" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
        </div> -->

    <div class="col-auto d-flex">
      <button type="button" [class]="classButtonPeriodo(1)" (click)="changePeriodo(1)">24 horas</button>
      <button type="button" [class]="classButtonPeriodo(7)" (click)="changePeriodo(7)">1 Semana</button>
      <button type="button" [class]="classButtonPeriodo(30)" (click)="changePeriodo(30)">1 Mês</button>
      <button type="button" [class]="classButtonPeriodo(null)" (click)="changePeriodo(null)">Selecionar
        periodo</button>
    </div>

    <div class="col-auto" style="margin-top: 24px">
      <p-calendar *ngIf="selectedPeriodo == null" class="p-inputtext-sm" appendTo="body" dateFormat="dd/mm/yy"
        [(ngModel)]="rangeDates" [locale]="pt" selectionMode="range" [readonlyInput]="false" inputId="range">
      </p-calendar>
    </div>

    <!-- <div class="col-auto" style="margin-top: 29px">
            <button type="button" class="btn btn-info btn-sm btn" style="margin-right: 5px;" (click)="dashboard()"
                ngbTooltip="Atualizar"><i class="pi pi-search"></i></button>
        </div> -->

    <div class="col-auto" style="margin-left: 5px;">
      <button type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;" (click)="dashboard()"
        ngbTooltip="Pesquisar"><i class="pi pi-search"></i></button>
    </div>
  </div>
</nav>


<perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>
  <div *ngIf="loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>


  <div *ngIf="!loading" class="row p-fluid">
    <div class="col-sm-4">
      <div class="card card-dashboard">

        <div class="card-body">
          <div class="float-end">
            <i class="pi pi-whatsapp widget-icon" style="color: rgb(143, 197, 157); font-size: 30px;"></i>
          </div>
          <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Total de atendimentos</h5>
          <h3 class="mt-3 mb-3">{{cards?.totalAtendimentos}}</h3>
          <p class="mb-0 text-muted">
            <span class="text-success me-2"> </span>
            <span class="text-nowrap"> </span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="card card-dashboard">
        <div class="card-body">
          <div class="float-end">
            <i class="pi pi-comment widget-icon" style="color: rgb(219, 219, 118); font-size: 30px;"></i>
          </div>
          <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Atendimentos em espera</h5>
          <h3 class="mt-3 mb-3">{{cards?.emEspera}}</h3>
          <p class="mb-0 text-muted">
            <span class="text-danger me-2"><i class="mdi mdi-clock"></i>
              {{cards?.tempoMedioEspera | number:'1.2-2'}}</span>
            <span class="text-nowrap">Tempo médio aguardando (Em minutos)</span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="card card-dashboard">
        <div class="card-body">
          <div class="float-end">
            <i class="pi pi-check widget-icon" style="color: rgb(80, 147, 247); font-size: 30px;"></i>
          </div>
          <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Atendimentos finalizados</h5>
          <h3 class="mt-3 mb-3">{{cards?.finalizados}}</h3>
          <p class="mb-0 text-muted">
            <span class="text-success me-2"><i class="mdi mdi-clock"></i>
              {{cards?.tempoMedioAndamento | number:'1.2-2'}}</span>
            <span class="text-nowrap">Tempo médio em atendimento (Em minutos)</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading" class="row">
    <app-chart-line *ngIf="dataSetEvolucao != null" [series]="dataSetEvolucao.series" [label]="dataSetEvolucao.label">
    </app-chart-line>
  </div>


  <div *ngIf="!loading" class="row">
    <p-table [value]="dataSourceDepartamentos" styleClass="p-datatable-sm p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Departamento</th>
          <th>Em espera</th>
          <th>Em andamento</th>
          <th>Finalizado</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.departamento}}</td>
          <td style="background-color: rgb(234, 122, 114); color: white;">{{item.emEspera}}</td>
          <td style="background-color: rgb(129, 226, 143);">{{item.emAndamento}}</td>
          <td style="background-color: rgb(63, 146, 218); color: white;">{{item.finalizado}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</perfect-scrollbar>
