import { ChangeDetectorRef, Component } from '@angular/core';
import moment from 'moment';
import { DireitoService } from 'src/app/core/services/direito.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { GrupoUsuarioService } from 'src/app/core/services/grupo-usuario.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  dataSource = [];
  loading = false;
  form: FormGroup;
  displayModal = false;
  dropdownDepartamentos: any[] = [];
  dropdownFuncionarios = [];
  dropDownIntegracaoWhatsapp = [];
  dropDownGrupo = [];
  dropdownStatusFilter = [
    { value: 1, label: 'Somente usuários ativos' },
    { value: 0, label: 'Somente usuários inativos' },
    { value: null, label: 'Todos' },
  ]
  statusFilter = 1;

  constructor(
    private _usuarioService: UsuarioService,
    public direitoService: DireitoService,
    private _formBuilder: FormBuilder,
    private _integracaWhatsapp: IntegracaoWhatsappService,
    private departamentoService: DepartamentoService,
    private pessoaService: PessoaService,
    private _grupoUsuarioService: GrupoUsuarioService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.index();
    this.configPage();

    this.form = this._formBuilder.group({
      usuario: this._formBuilder.group({
        id: [null],
        email: [null, Validators.required],
        password: [null],
        pessoa_id: [null],
        integracao_whatsapp_id: [null],
        ativo: [null],
        departamento_id: [null],
        // funcionario_id: [null, Validators.required],
        grupo_usuario_id: [null, Validators.required],
        pessoa: [null],
        altera_senha: [null],
        departamentos: [null],
        usuario_automacao: [null],
        modo_espiao: [null],
        notificar_status_instancia: [false],
        enviar_identificacao_mensagem: [true],
        instancias: [null],
      }),
      pessoa: this._formBuilder.group({
        nome: [null, Validators.required],
        apelido: [null],
        celular_principal: [null],
      }),
    });
  }

  configPage() {
    this.dropDownIntegracaoWhatsapp = [];
    this._integracaWhatsapp.index().subscribe((json: any) => {
      json.map((item: any) => {
        this.dropDownIntegracaoWhatsapp.push({ value: item.id, label: `${item.whatsapp} - ${item.nome}` })
      })
    });

    this.dropdownDepartamentos = [];
    this.departamentoService.index().subscribe(json => {
      json.map((item: any) => {
        this.dropdownDepartamentos.push({ value: item.id, label: item.nome });
      })
    });

    this.dropdownFuncionarios = [];
    this.pessoaService.listFuncionarios().subscribe((funcionarios: any) => {
      this.dropdownFuncionarios.push({ value: '', label: 'Selecione' });
      if (funcionarios.length > 0) {
        funcionarios.map(item => {
          this.dropdownFuncionarios.push({ value: item.id, label: item.nome });
        })
      }
    })

    this.dropDownGrupo = [];
    this._grupoUsuarioService.index().subscribe(json => {
      this.dropDownGrupo.push({ value: null, label: 'Selecione' });
      json.map((item: any) => {
        this.dropDownGrupo.push({ value: item.id, label: item.nome });
      })
    })
  }


  showForm(id = null) {
    this.configPage();

    this.loading = true;
    this.displayModal = true;

    setTimeout(() => {
      this.form.reset();
      this.form.get('usuario.ativo')?.setValue(true);
      this.form.get('usuario.usuario_automacao')?.setValue(false);
      this.form.get('usuario.modo_espiao')?.setValue(false);
      this.form.get('usuario.notificar_status_instancia')?.setValue(false);
      this.form.get('usuario.instancias')?.setValue(null);
      this.form.get('usuario.departamentos')?.setValue(null);

      if (id != null) {
        this._usuarioService.show(id).subscribe((json: any) => {

          this.loading = false;
          this.displayModal = true;

          this.form.get('usuario')?.patchValue(json);
          this.form.get('pessoa.nome')?.setValue(json.pessoa.nome);
          this.form.get('pessoa.apelido')?.setValue(json.pessoa?.fisica?.apelido);
          this.form.get('pessoa.celular_principal')?.setValue(json.pessoa?.celular_principal);
          this.form.get('usuario.grupo_usuario_id').setValue(json.grupo_usuario_id)

          let departamentos: any[] = [];
          json.departamentos.map(departamento => {
            departamentos.push({ value: departamento.id, label: departamento.nome });
          });
          this.form.get('usuario.departamentos').setValue(departamentos);

          let instancias: any[] = [];
          json.instancias.map(instancia => {
            instancias.push({ value: instancia.id, label: `${instancia.whatsapp} - ${instancia.nome}` });
          });
          this.form.get('usuario.instancias')?.setValue(instancias);

          this.cdr.detectChanges();
        })
      } else {
        this.loading = false;
      }
    }, 1000);
  }


  index() {
    this.loading = true;

    let query = '?';

    if (this.statusFilter != null) {
      query += `&status=${this.statusFilter}`;
    }



    try {
      this._usuarioService.grid(query).subscribe(json => {
        this.loading = false;
        this.dataSource = []

        let usuarioSessao = JSON.parse(localStorage.getItem('user'));

        if (usuarioSessao.email != 'admin') {
          json = json.filter(u => { return u.email != 'admin' })
        }

        if (this.direitoService.can("USUARIO_ALTERAR")) {
          json.map(item => {
            this.dataSource.push({
              id: item.id,
              apelido: item.name,
              nome: item.nome_pessoa,
              departamento: item.departamento,
              email: item.email,
              whatsapp: item.whatsapp,
              created_at: moment(item.created_at).format("DD/MM/YYYY"),
            })
          })
        } else {
          let usuarioLogado = JSON.parse(localStorage.getItem('user'));
          let usuario = json.find(usuario => { return usuario.id == usuarioLogado.id });
          this.dataSource.push({
            id: usuario.id,
            apelido: usuario.name,
            nome: usuario.nome_pessoa,
            departamento: usuario.departamento,
            email: usuario.email,
            whatsapp: usuario.whatsapp,
            created_at: moment(usuario.created_at).format("DD/MM/YYYY"),
          })
        }
      })
    } catch (error) {

    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "departamentos");
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  save() {
    // let body: any = {
    //   email: this.form.get('usuario.email')?.value,
    //   password: this.form.get('usuario.email')?.value,
    //   pessoa_id: this.form.get('usuario.pessoa_id')?.value,
    //   integracao_whatsapp_id: this.form.get('usuario.integracao_whatsapp_id')?.value,
    //   ativo: this.form.get('usuario.ativo')?.value,
    // }

    // let funcionario = this.dropdownFuncionarios.find(x => { return x.value == this.form.get('usuario.funcionario_id')?.value });

    alert('Caso o usuário alterado seja o mesmo que esteja logado na plataforma, é preciso sair e entrar novamente para as alterações surtirem efeito.');

    let x = this.form.get('pessoa').value;

    let pessoa = {
      nome: x.nome,
      apelido: x.apelido
    }
    this.form.get('usuario.pessoa').setValue(pessoa);

    let instancias = this.form.get('usuario.instancias').value;
    instancias = instancias != null && instancias.length > 0 ? instancias.map(item => { return { instancia_id: item.value } }) : [];
    this.form.get('usuario.instancias').setValue(instancias);

    this._usuarioService.saveCRM(this.form?.value, this.form.get('usuario.id')?.value).subscribe(json => {

      if (this.form.get('usuario.id').value != null) {

        let usuario = JSON.parse(localStorage.getItem('user'));
        if (usuario.id == this.form.get('usuario.id').value) {
          this._usuarioService.show(this.form.get('usuario.id').value).subscribe(usuarioJson => {
            localStorage.setItem('user', JSON.stringify(usuarioJson));
          })
        }
      }

      this.index();

      this.displayModal = false;
    });
  }
}
