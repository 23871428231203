<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome', 'ativo', 'id']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>


      </div>

      <div class="p-toolbar-group-right d-flex">

        <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;"
          (click)="exportExcel()" ngbTooltip="Exportar para excel"><i class="pi pi-file-export"></i></button>

        <button type="button" class="btn btn-primary btn mr-2" (click)="showModal()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr>
      <th class="th-codigo">Código</th>
      <th>Nome</th>
      <th>Ativo</th>
      <th style="max-width:100px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td class="th-codigo">{{item.id}}</td>
      <td>{{item.nome}}</td>
      <td>{{item.ativo ? 'Sim' : 'Não'}}</td>
      <td style="max-width:100px;">
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="showModal(item.id)" ngbTooltip="Editar"><i
            class="pi pi-pencil"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>


<p-dialog header="Adicionar / Editar Produto" [(visible)]="modal" [style]="{width: '50vw', height: '70vh'}" [closeOnEscape]="false">

  <div *ngIf="loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>

  <p-tabView *ngIf="!loading">
    <p-tabPanel header="Dados do produto">
      <form *ngIf="!loading" [formGroup]="form" class="row g-2 p-3">

        <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
            <label class="form-check-label" for="customCheck2">Ativo</label>
          </div>
        </div>

        <div class="col-md-12">
          <label for="lastname2">Nome</label>
          <input pInputText class="form-control p-inputtext-sm" formControlName="nome" />
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>


      </form>

      <div *ngIf="!loading" class="row g-2 p-3">
        <div *ngFor="let item of campos_personalizados" class="col-md-3" style="padding-bottom: 30px;">
          <label [for]="item.campo">{{item.campo}}</label>

          <input *ngIf="item.tipo == 'STRING'" type="text" pInputText class="form-control p-inputtext-sm"
            [(ngModel)]="item.value" />

          <input *ngIf="item.tipo == 'DATE'" type="date" pInputText class="form-control p-inputtext-sm"
            [(ngModel)]="item.value" />

          <p-dropdown *ngIf="item.tipo == 'DROPDOWN'" appendTo="body" [filter]="true" placeholder="Selecione"
            [options]="item.opcoes" [(ngModel)]="item.value"></p-dropdown>

          <p-inputNumber *ngIf="item.tipo == 'CURRENCY'" class="form-control p-inputtext-sm" [(ngModel)]="item.value"
            mode="currency" currency="BRL">
          </p-inputNumber>

          <input *ngIf="item.tipo == 'NUMBER'" type="number" pInputText class="form-control p-inputtext-sm"
            [(ngModel)]="item.value" />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Imagens">


      <p-fileUpload #fubauto mode="basic" [customUpload]="true" name="demo[]" accept="image/*" maxFileSize="1000000"
        (uploadHandler)="myUploader($event)" [auto]="true" chooseLabel="Selecione a imagem"></p-fileUpload>


      <div class="row mt-2">
        <div class="col-2" *ngFor="let item of imagemList">
          <p-image appendTo="body" [src]="fileToSrc(item)" alt="Image" width="150" height="150" [preview]="true">
          </p-image>

          <span class="mt-2 ml-2">
            <button pButton pRipple type="button" label="Remover" class="p-button-danger p-button-sm"
              (click)="removeImagem(item)"></button>
          </span>
        </div>

      </div>

    </p-tabPanel>
  </p-tabView>



  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button [disabled]="form.invalid" type="button" class="btn btn-success btn mr-2" (click)="save()"
      ngbTooltip="Salvar"><i class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>
