import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArquivoService } from 'src/app/core/services/arquivo.service';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { DireitoService } from 'src/app/core/services/direito.service';
import { FunilAtendimentoService } from 'src/app/core/services/funil-atendimento.service';
import { GatilhoService } from 'src/app/core/services/gatilho.service';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { TagService } from 'src/app/core/services/tag.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-gatilhos',
  templateUrl: './gatilhos.component.html',
  styleUrls: ['./gatilhos.component.scss']
})
export class GatilhosComponent {
  page: String = 'LIST';

  items: any = [
    { label: 'Responder', icon: 'pi pi-comment', value: 1, command: () => { this.addAcao(1) } },
    { label: 'Delegar', icon: 'pi pi-user-plus', value: 2, command: () => { this.addAcao(2) } },
    { label: 'Tag', icon: 'pi pi-tag', value: 3, command: () => { this.addAcao(3) } },
    { label: 'Departamento', icon: 'pi pi-building', value: 4, command: () => { this.addAcao(4) } },
    { label: 'Status', icon: 'pi pi-bookmark-fill', value: 5, command: () => { this.addAcao(5) } },
    { label: 'Anexar', icon: 'pi pi-file', value: 6, command: () => { this.addAcao(6) } },
    { label: 'Canal de atendimento', icon: 'pi pi-whatsapp', value: 7, command: () => { this.addAcao(7) } },
    { label: 'Campos personalizados', icon: 'pi pi-edit', value: 8, command: () => { this.addAcao(8) } },
    { label: 'Direcionar para o ultimo atendente', icon: 'pi pi-user', value: 9, command: () => { this.addAcao(9) } },
    { label: 'Direcionar conversa para outra instância', icon: 'pi pi-arrow-right-arrow-left', value: 10, command: () => { this.addAcao(10) } },
    { label: 'Incluir etapa funil', icon: 'pi pi-filter', value: 11, command: () => { this.addAcao(11) } },
    { label: 'Validar horário de funcionamento por departamento', icon: 'pi pi-clock', value: 12, command: () => { this.addAcao(12) } },
    { label: 'Delegar usuario automaticamente referente ao departamento da conversa', icon: 'pi pi-user', value: 13, command: () => { this.addAcao(13) } },
    { label: 'Adicionar uma anotação interna', icon: 'pi pi-edit', value: 14, command: () => { this.addAcao(14) } },
    { label: 'Vincular ao departamento relacionado ao canal de atendimento', icon: 'pi pi-building', value: 15, command: () => { this.addAcao(15) } },
    { label: 'Desligar Typebot', icon: 'pi pi-robo', value: 16, command: () => { this.addAcao(16) } },
    { label: 'Direcionar para o departamento do ultimo atendimento', icon: 'pi pi-building', value: 17, command: () => { this.addAcao(17) } },
    { label: 'Disponibilizar agenda do departamento', icon: 'pi pi-calendar', value: 18, command: () => { this.addAcao(18) } },
    { label: 'Acionar IA', icon: 'pi pi-robo', value: 19, command: () => { this.addAcao(19) } },
  ]

  dicionario: any[] = [
    { dica: '{DAY_GREETING}', descricao: 'Bom dia, Boa tarde, Boa noite' },
    { dica: '{PRIMEIRO_NOME_LEAD}', descricao: 'Primeiro nome do cliente' },
  ];

  form: FormGroup;

  acaoList = [];

  dropdownUsuario = [
  ];

  palavrasChaves = "{NOME_ATENDENTE}, {NOME_DEPARTAMENTO}, [usuario@sessao], {USUARIO_SESSAO}, {PRIMEIRO_NOME_LEAD}";

  dropdownTag = [];

  dropdownDepartamento = [];

  dataSource = [];

  loading = false;

  dropdownCanalAtendimento = [];

  dropdownEtapa = [];

  dropdownStatus = [
    { value: "0", label: 'Aguardando' },
    { value: "1", label: 'Em andamento' },
    { value: "2", label: 'Finalizado' },
  ];

  uploadedFiles = [];

  dropdownCamposPersonalizados = [];

  dropdownFunil = [];

  funilSelected;

  dropdownDepartamentoEtapa = []

  configuracao: any = JSON.parse(localStorage.getItem('configuracao'));

  dropdownUsuarioDepartamento = [];

  dropdownGatilhos = [
    { value: 0, label: 'Ao criar um atendimento (Receptivo)' },
    { value: 1, label: 'Ao criar um atendimento (Ativo)' },
    { value: 2, label: 'Ao mudar a situação de um atendimento' },
    { value: 3, label: 'Ao realizar uma importação Salesforce' },
    { value: 4, label: 'Ao receber uma nova mensagem' },
  ]

  dropdownCampo = [
    { value: 'chat_contato.canal_atendimento_id', label: 'Instancia vinculada' },
    { value: 'chat_contato.situacao', label: 'Status do atendimento' }, 
    { value: 'chat_contato.novo_contato', label: 'Novo contato' }, 
    { value: 'chat_contato_mensagem.conteudo', label: 'Mensagem recebida' }, 
    { value: 'negociacao.etapa_funil_id', label: 'Etapa funil' }, 
  ]

  dropdownBoolean =[
    {value: true, label: 'Sim'},
    {value: false, label: 'Não'},
  ]

  dropdownCondicao = [
    { value: '=', label: 'Igual' },
    { value: '<>', label: 'Diferente' },
  ]
 

  gatilhoList: any[] = [];

  dropdownCanalAtendimentoGatilho = [];


  condicoesList = [];
  condicoesOrList = [];



  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private tagService: TagService,
    private departamentoService: DepartamentoService,
    private gatilhoService: GatilhoService,
    public direitoService: DireitoService,
    public arquivoService: ArquivoService,
    public canalAtendimentoService: IntegracaoWhatsappService,
    public campoPersonalizadoService: CampoPersonalizadoServiceService,
    public funilAtendimentoService: FunilAtendimentoService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      ativo: [null],
      nome: [null, Validators.required],
      // departamento_id: [null, Validators.required],
      departamentos: [null],
      acao: [null]
    }); 

    this.listUsuarios();

    this.listTags();

    this.listDepartamentos();

    this.index();

    this.listAllCanalAtendimento();

    this.listAllCamposPersonalizados();

    this.listFunil();

    if (this.configuracao.modulo_carteira) {
      this.items.push(
        { label: 'Vincular ao responsável da carteira', icon: 'pi pi-money', value: 1000, command: () => { this.addAcao(1000) } },
      )
    }

    if (!this.configuracao['modulo_agenda']) {
      let items = this.items.filter(item => {return item.value !== 18});
      this.items = items;
    }

    if (!this.configuracao.faculdade_estacio) {
      let gatilhos = this.dropdownGatilhos.filter(gatilho => {return gatilho.value != 3});
      this.dropdownGatilhos = gatilhos;
    }
  }

  

  adicionar() {
    this.form.reset();
    this.form.get('ativo').setValue(true);
    this.gatilhoList = [];
    this.condicoesList = [];
    this.condicoesOrList = [];
    this.acaoList = [];
    this.page = 'FORM';
  }

  listAllCamposPersonalizados() {
    this.dropdownCamposPersonalizados = [];
    let query = 'localidade=PESSOA';
    this.campoPersonalizadoService.index(null, query).subscribe(json => {
      json.map(item => {
        this.dropdownCamposPersonalizados.push({
          value: item.id,
          label: item.campo,
        })
      })
    })
  }

  addAcao(acao = null) {

    if (acao == null) {
      acao = this.form.get('acao').value;
    }

    let obj = this.items.find(x => { return x.value == acao });

    this.acaoList.push({
      tipo: acao,
      valor: null,
      habilitado: true,
      rodizio: false,
      descricao: obj['label'],
      files: null,
      objeto: null,
      usuarioRodizioList: this.dropdownUsuario.map(usuario => { return usuario.value }),
      usuarioRodizioValues: [],
      valorAuxiliar: null,
      mensagemAuxiliar: null,
      encerrarBot: false,
      horarios: [
        { diaSemana: 'Segunda-feira', dia: 1, inicio: null, fim: null, validar: true, mensagem: null },
        { diaSemana: 'Terça-feira', dia: 2, inicio: null, fim: null, validar: true, mensagem: null },
        { diaSemana: 'Quarta-feira', dia: 3, inicio: null, fim: null, validar: true, mensagem: null },
        { diaSemana: 'Quinta-feira', dia: 4, inicio: null, fim: null, validar: true, mensagem: null },
        { diaSemana: 'Sexta-feira', dia: 5, inicio: null, fim: null, validar: true, mensagem: null },
        { diaSemana: 'Sábado', dia: 6, inicio: null, fim: null, validar: true, mensagem: null },
        { diaSemana: 'Domingo', dia: 7, inicio: null, fim: null, validar: true, mensagem: null },
      ],
    })
  }

  listUsuarios() {
    this.usuarioService.index().subscribe(json => {
      json.map(item => {
        this.dropdownUsuario.push({ value: item.id, label: item.email, departamentoId: item.departamento_id });
      })
    })
  }

  getUsuarioDepartamento(departamentoId, acao) {
    let usuarios = this.dropdownUsuario.filter(usuario => { return usuario.departamentoId == departamentoId });
    acao.usuarioRodizioList = usuarios;
    acao.usuarioRodizioValues = usuarios.map(item => { return item.value });
  }

  listFunil() {
    this.funilAtendimentoService.index().subscribe(json => {
      json.map(item => {
        item.etapas.map(etapa => {
          this.dropdownFunil.push({ value: `${etapa.id}`, label: `${item.nome} - ${etapa.nome}` });
        })
      })
    })
  }

  // checkRodizioUsuario(event, item) {

  //   if (event.checked) {
  //     item.valor = 'AUTOMATICO';
  //   } else {
  //     item.valor = null;
  //   }
  // }

  listTags() {
    this.tagService.index().subscribe(json => {
      this.dropdownTag = json.map(item => { return { value: item.id, label: item.descricao } });
    })
  }

  listDepartamentos() {
    this.departamentoService.index().subscribe(json => {
      this.dropdownDepartamentoEtapa = json.map(item => { return { value: item.id, label: item.nome } });
      this.dropdownDepartamento = json;
    })
  }

  deleteItem(item) {
    let arr = this.acaoList.filter(x => { return x != item });
    this.acaoList = arr;
  }

  save(duplicate = null) {

    let form = this.form.value;

    let condicoes = [];
    this.condicoesList.map(item => {
      condicoes.push(item)
    });
    this.condicoesOrList.map(item => {
      condicoes.push(item)
    })

    let body = {
      dialogo: {
        id: form.id,
        nome: form.nome,
        ativo: form.ativo,
        departamentos: form.departamentos,
      },
      acoes: this.acaoList,
      acionamentos: this.gatilhoList.map(gatilho => {return {tipo: gatilho.tipo}}),
      condicoes: condicoes
    }

    if (duplicate == null) {
      this.gatilhoService.save(body, body.dialogo.id).subscribe(json => {
        this.page = 'LIST';
        this.index();
      })
    } else {
      this.gatilhoService.save(body, null).subscribe(json => {
        this.page = 'LIST';
        this.index();
      })
    }



  }

  index() {
    this.dataSource = [];
    this.gatilhoService.index().subscribe(json => {
      json.forEach(element => {
        this.dataSource.push({
          id: element.id,
          nome: element.nome,
        })
      });
    })
  }

  setForm(json) {
    this.condicoesList = [];
    this.condicoesOrList = [];
    this.form.patchValue(json);

    json.acoes.map(item => {
      let acao = JSON.parse(item.acao);

      if (acao['tipo'] == 6) {
        this.arquivoService.show(acao['valor']).subscribe(json => {
          acao['files'] = [json];
        })
      }
    

      if (acao['tipo'] == '4') {
        let usuarios = acao['usuarioRodizioList'] || null;
        if (usuarios == null) {
          this.getUsuarioDepartamento(acao['valor'], acao);
        }
      }

      this.acaoList.push(acao);
    }) 

    this.gatilhoList = json.acionamentos;
    this.condicoesList = json.condicoes.filter(item => {return item.regra == 'AND'});
    this.condicoesOrList = json.condicoes.filter(item => {return item.regra == 'OR'});

    if (json.departamentos.length > 0) {
      let departamentosList = [];
      json.departamentos.map(item => {
        departamentosList.push(item.departamento);
      });
      this.form.get('departamentos').setValue(departamentosList);
    }
  }

  show(id) {
    this.page = 'FORM';
    this.form.reset();
    this.acaoList = [];
    this.condicoesOrList = [];
    this.condicoesList = [];
    this.loading = true;
    this.gatilhoService.show(id).subscribe(json => {
      this.setForm(json);
      this.loading = false;
    });
  }

  duplicate(id) {
    this.loading = true;

    this.acaoList = [];

    let condicoes = [];
    this.condicoesList.map(item => {
      condicoes.push(item)
    });
    this.condicoesOrList.map(item => {
      condicoes.push(item)
    })


    this.gatilhoService.show(id).subscribe(json => {
      this.setForm(json);

      let nomeCopia = 'COPIA: ' + this.form.get('nome').value;
      this.form.get('nome').setValue(nomeCopia);

      let body = {
        dialogo: this.form.value,
        acoes: this.acaoList,
        acionamentos: this.gatilhoList.map(gatilho => {return {tipo: gatilho.tipo}}),
        condicoes: condicoes
      }

      this.gatilhoService.save(body, null).subscribe(json => {
        this.acaoList = [];
        this.gatilhoService.show(json.id).subscribe(json => {
          this.setForm(json);
          this.index();
          this.loading = false;
        });
      });
    })
  }

  uploadHandlerDoc(event, item) {
    let formData = new FormData();

    formData.append("myFile", event['files'][0]);

    this.arquivoService.uploadFile(formData).subscribe((json) => {
      item['valor'] = json[0]['id'];
    });
  }

  deleteFile(item) {
    item.files = [];
  }

  listAllCanalAtendimento() {
    this.dropdownCanalAtendimentoGatilho = [{ value: null, label: 'Todas' }];

    this.canalAtendimentoService.index().subscribe(json => {
      this.dropdownCanalAtendimento = json.map(item => { return { value: item.id.toString(), label: item.nome } });

      json.map(item => {
        this.dropdownCanalAtendimentoGatilho.push({ value: item.id.toString(), label: item.nome });
      })

    })
  }

  changeGatilho() {

  }

  addGatilho() { 
    this.gatilhoList.push({tipo: null})
  }

  removerGatilho(item) {
    let gatilhos = this.gatilhoList.filter(gatilho => {return gatilho != item});
    this.gatilhoList = gatilhos;

    if (this.gatilhoList.length == 0) {
      this.addGatilho();
    }
  }

  addCondicao() {
    this.condicoesList.push({campo: null, condicao: null, valor: null, regra: 'AND'});
  }

  addOrCondicao() {
    this.condicoesOrList.push({campo: null, condicao: null, valor: null, regra: 'OR'});
  }

  removerCondicao(item, regra) {
    if (regra == 'AND') {
      let condicoes = this.condicoesList.filter(condicao => {return condicao != item});
      this.condicoesList = condicoes;
    }

    if (regra == 'OR') {
      let condicoes = this.condicoesOrList.filter(condicao => {return condicao != item});
      this.condicoesOrList = condicoes;
    }
  }
}
