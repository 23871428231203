<!-- Start Contact content -->
<div style="height: calc(100vh - 100px);">
  <div class="p-4">
    <div class="user-chat-nav float-end">
      <button type="button" (click)="showModalCadastro()" class="btn btn-primary btn mr-2" style="margin-right: 5px;"
        ngbTooltip="Atualizar lista de contatos"><i class="pi pi-user-plus"></i></button>
      <!-- <div ngbTooltip="Adicionar contato">
        <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0" data-toggle="modal"
          data-target="#addContact-exampleModal" (click)="showModalCadastro()">
          <i class="ri-user-add-line"></i>
        </button>
      </div> -->



    </div>

    <div class="user-chat-nav float-end mr-2" style="margin-right: 10px;">
      <button type="button" (click)="updateListContacts()" class="btn btn-success btn mr-2" style="margin-right: 5px;"
        ngbTooltip="Atualizar lista de contatos"><i class="pi pi-refresh"></i></button>

    </div>


    <h4 class="mb-4">Contatos</h4>

    <!-- Start Add contact Modal -->
    <ng-template *ngIf="false" #content let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16" id="addContact-exampleModalLabel">
            Dados do contato</h5>
          <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')">
          </button>
        </div>
        <div class="modal-body p-4">

          <p-tabView>
            <p-tabPanel header="Dados principais">
              <form [formGroup]="form">

                <div class="col-md-3 mb-12" style="padding-bottom: 30px;" formGroupName="pessoa">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
                    <label class="form-check-label" for="customCheck2">Ativo</label>
                  </div>
                </div>

                <div class="mb-3" formGroupName="pessoa">
                  <label for="inputtext">Nome</label>
                  <input id="firstname2" type="text" class="form-control" pInputText formControlName="nome">
                  <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
                </div>

                <div class="mb-3 p-fluid" formGroupName="pessoa">
                  <label for="inputtext">Celular/Whatsapp</label>
                  <p-inputMask mask="+99 (99) 99999-9999" formControlName="celular_principal">
                  </p-inputMask>
                  <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
                </div>

                <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" class=" mb-3" formGroupName="fisica">
                  <label for="inputtext">Apelido</label>
                  <input id="firstname2" type="text" class="form-control" pInputText formControlName="apelido">
                </div>

                <div class="col-md-12 mb-3 p-fluid" formGroupName="tag">
                  <label>Tags</label>
                  <p-multiSelect [options]="dropDownTag" formControlName="tags" optionLabel="label">
                  </p-multiSelect>
                </div>


                <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" formGroupName="fisica" class="mb-3 p-fluid">
                  <label for="inputtext">Nascimento</label>
                  <p-calendar dateFormat="dd/mm/yy" [locale]="pt" formControlName="nascimento">
                  </p-calendar>
                </div>


              </form>
            </p-tabPanel>
            <p-tabPanel header="Header 2">
              Content 2
            </p-tabPanel>
            <p-tabPanel header="Header 3">
              Content 3
            </p-tabPanel>
          </p-tabView>

          <!-- <form>
            <div class="mb-3">
              <label class="form-label" for="addcontactemail-input">{{'chat.tabs.contacts.modal.form.email.label' |
                translate}}</label>
              <input type="email" class="form-control" id="addcontactemail-input"
                placeholder="{{'chat.tabs.contacts.modal.form.email.placeholder' | translate}}">
            </div>
            <div class="mb-3">
              <label class="form-label"
                for="addcontact-invitemessage-input">{{'chat.tabs.contacts.modal.form.message.label' |
                translate}}</label>
              <textarea class="form-control" id="addcontact-invitemessage-input" rows="3"
                placeholder="{{'chat.tabs.contacts.modal.form.message.placeholder' | translate}}"></textarea>
            </div>
          </form> -->


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" (click)="modal.dismiss('Cross click')">Fechar</button>

          <button type="button" class="btn btn-primary" (click)="save()">Salvar</button>
        </div>
      </div>
    </ng-template>
    <!-- End Add contact Modal -->

    <div class="search-box chat-search-box">

      <input class="form-check-input mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="name"
        checked [(ngModel)]="filterType"> Nome
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="whatsapp"
        [(ngModel)]="filterType"> Whatsapp


      <div class="row d-flex align-items-center">

        <div class="col-sm-11">
          <input *ngIf="filterType == 'name'" style="width: 100%;" class="p-inputtext p-component p-element p-inputmask"
            type="text" [(ngModel)]="filterValue" placeholder="Pesquisar...">
          <p-inputMask *ngIf="filterType == 'whatsapp'" class="p-fluid" mask="+55 (99) 99999-9999"
            [(ngModel)]="filterValue" placeholder="+55 (99) 99999-9999"></p-inputMask>



        </div>
        <div class="col-sm-1">

          <button type="button" (click)="lazyLoadContacts()" class="btn btn-primary" ngbTooltip="Procurar"><i
              class="pi pi-search"></i></button>
        </div>




      </div>



      <p-paginator pageLinkSize="0" class="custom-paginator" [rows]="100" [totalRecords]="totalRecords"
        (onPageChange)="lazyLoadContacts($event)"></p-paginator>
    </div>

    <div>

      <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#">Anterior</a>
            </li>
           
            <li class="nav-item">
              <a class="nav-link" href="#">1</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">2</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">3</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Próximo</a>
            </li>
          </ul>
        </div>
      </nav>

      <nav class="nav">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts('-')">Anterior</a></li>
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts(1)">1</a></li>
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts(2)">2</a></li>
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts(3)">3</a></li>
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts(4)">4</a></li>
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts(5)">5</a></li>
          <li class="page-item"><a class="page-link" href="#" (click)="lazyLoadContacts('+')">Próximo</a></li>
        </ul>
      </nav> -->
    </div>


    <!-- End search-box -->
  </div>
  <!-- end p-4 -->

  <div class="p-4 text-center">
    <p-progressSpinner *ngIf="loading" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
      strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>


  <!-- Start contact lists -->
  <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>



    <div *ngFor="let item of contactsList">
      <div class="p-3 font-weight-bold text-primary">
        {{item.key}}
      </div>


      <ul class="list-unstyled contact-list">
        <li *ngFor="let item of item.contacts" (click)="select(item)" [ngStyle]="getStyle(item)">
          <div class="d-flex align-items-center">

            <div class="avatar-xs" *ngIf="!item.profilePicture">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                {{(item.name | translate).charAt(0)}}
              </span>
            </div>

            <div *ngIf="item.profilePicture" class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="{{urlS3}}/{{item.profilePicture}}" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <div class="flex-grow-1" style="margin-left: 10px;">
              <h5 class="font-size-14 m-0">{{item.name | translate}}</h5>
            </div>


            <div class="dropdown" ngbDropdown>
              <a href="javascript: void(0);" class="text-muted dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="ri-more-2-fill"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript:void(0);">Editar <i class="ri-edit-line float-end text-muted"
                    (click)="show(item.id)"></i></a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="newChat(item)">Novo chat <i
                    class="ri-message-line float-end text-muted"></i></a>

              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- end contact list A -->

  </perfect-scrollbar>
  <!-- end contact lists -->
</div>
<!-- Start Contact content -->

<p-dialog header="Cadastrar contato" [(visible)]="modalCadastro" [style]="{width: '50vw'}">

  <p-tabView>
    <p-tabPanel header="Dados principais">
      <form [formGroup]="form" class="row">
        <div class="col-md-auto mb-12" style="padding-bottom: 30px;" formGroupName="pessoa">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
            <label class="form-check-label" for="customCheck2">Ativo</label>
          </div>
        </div>
        <div class="col-md-auto mb-12" style="padding-bottom: 30px;" formGroupName="pessoa">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="black_list">
            <label class="form-check-label" for="customCheck2">Não receber mensagens de campanha</label>
          </div>
        </div>

        <div class="mb-3" formGroupName="pessoa">
          <label for="inputtext">Tipo</label>
          <p-dropdown class="p-inputtext-sm" [options]="dropdownTipo" formControlName="tipo"></p-dropdown>
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>

        <div class="mb-3" formGroupName="pessoa">
          <label for="inputtext">Nome</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="nome">
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>

        <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA'" class="mb-3" formGroupName="juridica">
          <label for="inputtext">Razão Social</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
            formControlName="razao_social">
        </div>

        <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA'" class="mb-3 p-fluid" formGroupName="juridica">
          <label for="inputtext">CNPJ</label>
          <p-inputMask mask="99.999.999/9999-99" formControlName="cnpj" class="p-inputtext-sm">
          </p-inputMask>
        </div>

        <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" class=" mb-3" formGroupName="fisica">
          <label for="inputtext">Apelido</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="apelido">
        </div>

        <div *ngIf="form.get('pessoa.tipo').value == 'FISICA'" class="p-fluid mb-3" formGroupName="fisica">
          <label for="inputtext">CPF</label>
          <p-inputMask mask="999.999.999-99" formControlName="cpf" class="p-inputtext-sm">
          </p-inputMask>
        </div>

        <div class="mb-3 p-fluid" formGroupName="pessoa">
          <label for="inputtext">Celular/Whatsapp</label>
          <p-inputMask mask="+99 (99) 99999-9999" formControlName="celular_principal" class="p-inputtext-sm">
          </p-inputMask>
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>

        <div class="col-md-12 mb-3 p-fluid" formGroupName="tag">
          <label>Tags</label>
          <p-multiSelect [options]="dropDownTag" formControlName="tags" optionLabel="label">
          </p-multiSelect>
        </div>

        <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" formGroupName="fisica" class="mb-3 p-fluid">
          <label for="inputtext">Nascimento</label>
          <p-calendar dateFormat="dd/mm/yy" [locale]="pt" class="p-inputtext-sm" formControlName="nascimento">
          </p-calendar>
        </div>
      </form>
    </p-tabPanel>
    <p-tabPanel header="Endereço">
      <form [formGroup]="form" class="row">

        <div class="mb-3 col-md-10" formGroupName="endereco">
          <label for="inputtext">CEP</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="cep">
        </div>

        <div class=" mb-3 col-md-2" formGroupName="endereco" style="margin-top: 20px;">
          <p-button icon="pi pi-search" styleClass="p-button-sm" (click)="buscaCEP()"></p-button>
        </div>

        <div class=" mb-3 col-md-10" formGroupName="endereco">
          <label for="inputtext">Endereço</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
            formControlName="logradouro">
        </div>

        <div class=" mb-3 col-md-2" formGroupName="endereco">
          <label for="inputtext">Numero</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="numero">
        </div>

        <div class=" mb-3" formGroupName="endereco">
          <label for="inputtext">Complemento</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
            formControlName="complemento">
        </div>

        <div class=" mb-3" formGroupName="endereco">
          <label for="inputtext">Bairro</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="bairro">
        </div>

        <div class=" mb-3 col-md-10" formGroupName="endereco">
          <label for="inputtext">Municipio</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="municipio">
        </div>

        <div class=" mb-3 col-md-2" formGroupName="endereco">
          <label for="inputtext">UF</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="uf">
        </div>






      </form>
    </p-tabPanel>
  </p-tabView>

  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modalCadastro = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
        class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>


<p-dialog header="Atualização da lista de contatos" [(visible)]="modalUpdateContacts" [style]="{width: '30vw'}">
  <div class="row">
    <div class="col-md-12">
      <p-dropdown appendTo="body" [options]="dropdownIntegracaoWhatsapp" placeholder="Selecione"
        [(ngModel)]="instancia"></p-dropdown>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalUpdateContacts=false" label="Cancelar" styleClass="p-button-danger">
    </p-button>

    <p-button [disabled]="instancia ? false : true" icon="pi pi-refresh" (click)="updateListContacts()"
      label="Atualizar" styleClass="p-button-success"></p-button>
  </ng-template>
</p-dialog>
