import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaComponent } from './agenda.component';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TabViewModule } from 'primeng/tabview';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DialogModule } from 'primeng/dialog';


@NgModule({
  declarations: [AgendaComponent], 
  imports: [
    CommonModule,
    FullCalendarModule,
    TabViewModule,
    PerfectScrollbarModule,
    DialogModule
  ],
  exports: [AgendaComponent]
})
export class AgendaModule { }
