import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConexaoPolosService } from 'src/app/core/services/conexao-polos.service';

@Component({
  selector: 'app-assistente-conexao-polos',
  templateUrl: './assistente-conexao-polos.component.html',
  styleUrls: ['./assistente-conexao-polos.component.css']
})
export class AssistenteConexaoPolosComponent implements OnInit {

  dataSource: any[] = [];
  modal: boolean = false;
  showInputToken: boolean = false;
  form: FormGroup
  intervalDemanda;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private conexaoPolosService: ConexaoPolosService,
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      id: [null],
      status: [null],
      token: [null, Validators.required],
    });

    this.findAll();
  }

  findAll() {
    this.conexaoPolosService.index().subscribe(json => {
      this.dataSource = json;
      this.form.reset();
    })
  }

  getStatusLabel(status) {
    if (status == 0) {
      return 'Aguardando execução do bot'
    } else if (status == 1) {
      return 'Aguardando código do SMS'
    } else if (status == 2) {
      return 'Código informado, aguardando bot'
    } else if (status == 3) {
      return 'Demanda finalizada'
    }
  }


  create() {
    this.showInputToken = false;
    this.form.reset();
    this.modal = true;


    let demandaEmProgresso = this.dataSource.find(demanda => { return demanda.status != 3 });
    if (demandaEmProgresso != undefined) {
      this.form.patchValue(demandaEmProgresso);
      alert('Voce tem uma demanda em andamento');
      this.showSMSToken();
    } else {
      this.loading = true;
      this.conexaoPolosService.save(null, null).subscribe(json => {
        this.loading = false
        this.showSMSToken();
        this.form.patchValue(json);
      })
    }


  }

  showSMSToken() {
    this.loading = true;
    this.intervalDemanda = setInterval(() => {
      this.conexaoPolosService.show(this.form.get('id').value).subscribe(json => {
        this.form.get('status').setValue(json.status);

        if (this.form.get('status').value == 1) {
          this.loading = false;
          this.showInputToken = true;
        }

        if (this.form.get('status').value == 2) {
          this.showInputToken = false;
        }

        if (this.form.get('status').value == 3) {
          this.ngOnDestroy();
          this.loading = false;
          this.showInputToken = false;
          this.modal = false;
          this.findAll();
        }
      })
    }, 3000);
  }



  sendToken() {

    let body = {
      status: 2,
      token: this.form.get('token').value
    }

    this.loading = true;
    this.conexaoPolosService.save(body, this.form.get('id').value).subscribe(json => {
      this.showInputToken = false;
      // this.modal = false;
    })
  }

  ngOnDestroy(): void {
    if (this.intervalDemanda) {
      clearInterval(this.intervalDemanda);
    }
  }


}
