<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>

        <div class="col-sm-8 ml-2 p-fluid" style="margin-left: 5px;">
          <p-dropdown [options]="dropdownStatusFilter" [filter]="true" appendTo="body" class="p-inputtext-sm"
            [(ngModel)]="statusFilter">
          </p-dropdown>
        </div>

        <div class="col-auto" style="margin-left: 5px;">
          <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;"
            (click)="index()" ngbTooltip="Pesquisar"><i class="pi pi-search"></i></button>
        </div>


      </div>

      <div class="p-toolbar-group-right d-flex">

        <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;"
          (click)="exportExcel()" ngbTooltip="Exportar para excel"><i class="pi pi-file-export"></i></button>

        <button *ngIf="direitoService.can('USUARIO_INSERIR')" type="button" class="btn btn-primary btn mr-2"
          (click)="showForm()" ngbTooltip="Adicionar"><i class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr c>
      <th class="th-codigo">Código</th>
      <th>Nome</th>
      <th>Email</th>
      <th style="max-width:100px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td class="th-codigo">{{item.id}}</td>
      <td>{{item.nome}}</td>
      <td>{{item.email}}</td>
      <td style="max-width:100px;">
        <button *ngIf="direitoService.can('USUARIO_ALTERAR')" type="button" class="btn btn-primary btn-sm mr-2"
          (click)="showForm(item.id)" ngbTooltip="Editar"><i class="pi pi-pencil"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Adicionar / Editar Usuário" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
  [draggable]="false">

  <div class="font-13 box-shadow-custom">

    <div *ngIf="loading" class="text-center">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>

    <p-panel *ngIf="!loading" header="Dados do usuário">
      <form [formGroup]="form" class="row g-2 p-3">
        <div *ngIf="form.get('usuario.id').value != null" class="col-md-4 mb-4" formGroupName="usuario">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="altera_senha">
            <label class="form-check-label" for="customCheck2">Alterar senha</label>
          </div>
        </div>

        <div class="col-sm-12 mb-4" formGroupName="usuario">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
            <label class="form-check-label" for="customCheck2">Ativo</label>
          </div>
        </div>

        <div class="col-sm-12 mb-4" formGroupName="usuario">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2"
              formControlName="enviar_identificacao_mensagem">
            <label class="form-check-label" for="customCheck2">Identificar nome na mensagem</label>
          </div>
        </div>



        <div class="col-sm-12 mb-4" formGroupName="usuario">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="usuario_automacao">
            <label class="form-check-label" for="customCheck2">Usuário da automação</label>
          </div>
        </div>

        <div class="col-sm-12 mb-4" formGroupName="usuario">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="modo_espiao" formControlName="modo_espiao">
            <label class="form-check-label" for="modo_espiao">Modo espião</label>
          </div>
        </div>


        <div class="col-sm-12" formGroupName="pessoa">
          <label for="inputtext">Nome</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="nome">
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>

        <div class="col-sm-12" formGroupName="pessoa">
          <label for="inputtext">WhatsApp</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
            formControlName="celular_principal">
        </div>

        <div class="col-sm-12" formGroupName="pessoa">
          <label for="inputtext">Apelido</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="apelido">
          <small class="badge badge-soft-danger me-1 rounded-pill">O valor informado sera o
            mesmo
            valor exibido como identificação do consultor no chat</small>
        </div>


        <!-- <div class="col-sm-6 p-fluid" formGroupName="usuario">
          <label for="inputtext">Instancia whatsapp</label>
          <p-dropdown formControlName="integracao_whatsapp_id" class="p-inputtext-sm"
            [options]="dropDownIntegracaoWhatsapp"></p-dropdown>
        </div> -->

        <div class="col-sm-6 p-fluid" formGroupName="usuario">
          <label for="inputtext">Canais de atendimento</label>
          <p-multiSelect [options]="dropDownIntegracaoWhatsapp" appendTo="body" formControlName="instancias"
            class="p-inputtext-sm" optionLabel="label">
          </p-multiSelect>
        </div>

        <div class="col-sm-6 p-fluid" formGroupName="usuario">
          <label for="inputtext">Grupo do usuario</label>
          <p-dropdown [options]="dropDownGrupo" [filter]="true" appendTo="body" class="p-inputtext-sm"
            formControlName="grupo_usuario_id">
          </p-dropdown>
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>

        <div class="col-sm-6 p-fluid" formGroupName="usuario">
          <label for="inputtext">Departamento Principal</label>
          <p-dropdown appendTo="body" [filter]="true" placeholder="Selecione" formControlName="departamento_id"
            class="p-inputtext-sm" [options]="dropdownDepartamentos"></p-dropdown>
        </div>

        <div class="col-sm-6 p-fluid" formGroupName="usuario">
          <label for="inputtext">Outros departamentos</label>
          <p-multiSelect [options]="dropdownDepartamentos" appendTo="body" formControlName="departamentos"
            class="p-inputtext-sm" optionLabel="label">
          </p-multiSelect>
        </div>

        <div class="col-sm-12" formGroupName="usuario">
          <label for="inputtext">E-mail / Login</label>
          <input id="firstname2" type="text" class="form-control p-inputtext-sm" formControlName="email" pInputText>
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>

        <div *ngIf="form.get('usuario.altera_senha').value == true || form.get('usuario.id').value == null"
          class="col-sm-12 p-fluid" formGroupName="usuario">

          <label for="inputtext">Senha</label>
          <p-password formControlName="password" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte"
            class="p-inputtext-sm" pInputText>
          </p-password>
          <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>
      </form>
    </p-panel>
    <p-panel *ngIf="!loading" header="Notificações" [ngStyle]="{ 'margin-top': '10px' }">
      <form [formGroup]="form" class="row g-2 p-3">
        <div class="col-md-12" formGroupName="usuario">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="notificar_status_instancia"
              formControlName="notificar_status_instancia">
            <label class="form-check-label" for="notificar_status_instancia">Status de conexão das instancias</label>
          </div>
        </div>
      </form>
    </p-panel>
  </div>



  <!-- <ng-template pTemplate="footer">
    <div>
      <button (click)="displayModal=false" label="Fechar" type="button"
        class="btn btn-danger waves-effect waves-light me-1">Fechar</button>

      <button [disabled]="form?.invalid" (click)="save()" label="Salvar" type="button"
        class="btn btn-success waves-effect waves-light me-1">Salvar</button>
    </div>
  </ng-template> -->

  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="displayModal = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
        class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>
