import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegracaoEleittoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'integracao-eleitto');
  }

  listConfiguracaoAtendimento(): Observable<any> {
      return this.http.get(`${this.url_base}/${this.prefixo}/configuracao-atendimento`);
  }
  
}
