import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { RelatorioService } from 'src/app/core/services/relatorio.service';
import { ResumoDiaService } from 'src/app/core/services/resumo-dia.service';

@Component({
  selector: 'app-resumo-dia',
  templateUrl: './resumo-dia.component.html',
  styleUrls: ['./resumo-dia.component.css']
})
export class ResumoDiaComponent implements OnInit {
  @Input() modal: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  cards = {
    count: 0,
    countAguardando: 0,
    countAndamento: 0,
    countFinalizado: 0
  }

  valorDia: Number = 0;

  constructor(
    private relatorioService: RelatorioService,
    private resumoDiaService: ResumoDiaService,
  ) { }

  ngOnInit() {
    this.findResumoDia();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.findResumoDia();
  }

  close() {
    this.modal = false;
    this.onClose.emit();
  }

  findResumoDia() {
    let usuario = JSON.parse(localStorage.getItem('user'));

    let request = 'usuario_id=' + usuario.id + '&data=' + moment(new Date()).format("YYYY-MM-DD");

    this.resumoDiaService.index(null, request).subscribe(json => {
      if (json != null) {
        this.valorDia = json[0].valor;
      }
    })

    this.relatorioService.findResumoDia(usuario.id).subscribe(json => {

      this.cards.count = json.conversasDoDia.length;

      let aguardando = json.conversasDoDia.filter(c => { return c.situacao == 0 });
      this.cards.countAguardando = aguardando.length;

      let andamento = json.conversasDoDia.filter(c => { return c.situacao == 1 });
      this.cards.countAndamento = andamento.length;

      let finalizado = json.conversasDoDia.filter(c => { return c.situacao == 2 });
      this.cards.countFinalizado = finalizado.length;

    })
  }

  save() {
    let usuario = JSON.parse(localStorage.getItem('user'));

    let body = {
      usuario_id: usuario.id,
      valor: this.valorDia,
      total_conversas: this.cards.count,
      total_aguardando: this.cards.countAguardando,
      total_andamento: this.cards.countAndamento,
      total_finalizada: this.cards.countFinalizado,
    }

    this.resumoDiaService.save(body, null).subscribe(json => {
      this.close();
    })
  }
}
