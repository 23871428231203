import { Component } from '@angular/core';
import moment from 'moment';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';

@Component({
  selector: 'app-instancia-list',
  templateUrl: './instancia-list.component.html',
  styleUrls: ['./instancia-list.component.scss']
})
export class InstanciaListComponent {
  canalAtendimentoList: any[] = [];
  direitoInserir: any[] = [];
  getAll: any = null;
  modal = false;
  instanciaSelected;
  loading = false;

  constructor(
    private _integracaoWhatsappService: IntegracaoWhatsappService
  ) { }

  ngOnInit(): void {
    let direitos = JSON.parse(localStorage.getItem('direito'));
    let data: any[] = []
    this.direitoInserir = direitos.find(item => item.flag == 'CANAL_ATENDIMENTO_INSERIR')

    this.loading = true;

    this._integracaoWhatsappService.index().subscribe(json => {
      
      json.forEach((element: any) => {
        var consultaUTC = moment.utc(element['ultima_consulta_status']).toDate();
        var consultaLocal = moment(consultaUTC).local().format('DD/MM/YYYY HH:mm');
        element['ultima_consulta_status'] = consultaLocal;
        this.canalAtendimentoList.push(element);
      });


      this.canalAtendimentoList.map(element => {
        try {
          this._integracaoWhatsappService.connect(element['id']).subscribe(res => {
            element['online'] = res['connected'];
            this.canalAtendimentoList.sort((a, b) => {
              if (a.online === b.online) {
                return 0;
              }
              return a.online ? 1 : -1;
            });
          }, err => {
          })
        } catch (error) {
        }
      })

    }, err => {

    });

    this.loading = false;
  }

  adicionar() {
    this.modal = true;
  }

  show(instancia) {
    this.instanciaSelected = instancia;
    this.adicionar();
  }

  ngOnDestroy(): void {
    if (this.getAll) {
      clearInterval(this.getAll);
    }
  }

  styleInstanciaOnline(instancia) {
    if (instancia.online) {
      return { 'color': 'green', 'cursor': 'pointer' }
    } else {
      return { 'color': 'red', 'cursor': 'pointer' }
    }
  }
}
