import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends GenericService {

  constructor(http: HttpClient) { 
    super(http, 'user');
  }

  authenticate(params = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/authenticate`, params);
  }

  storeCrm(params = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/crm`, params);
  }

  updateCrm(id, params = null): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/crm/${id}`, params);
  }

  listByDepartamento(departamentoId): Observable<any> {
    if (departamentoId == null) {
      return;
    }
    return this.http.get(`${this.url_base}/${this.prefixo}/list-by-departamento/${departamentoId}`);
  }

  saveCRM(params = null, id = null) {
    if (id != null) {
      return this.http.put(`${this.url_base}/${this.prefixo}/update/crm/${id}`, params);
    } else {
      return this.http.post(`${this.url_base}/${this.prefixo}/store/crm`, params);
    }
  }

  getDepartamentos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/departamentos`);
  }

  listInstancias(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/instancias/${id}`);
  }

  departamentos(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/departamentos/${id}`);
  }


}
