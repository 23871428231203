import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'relatorio');
  }

  pessoas(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/pessoas`, body);
  }

  reportPessoaGroupByCampoPersonalizado(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/pessoas/group-by/campo-personalizado`, body);
  }

  findResumoDia(usuarioId = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/resumo-dia`;
    let query = '';

    if (usuarioId != null) {
      query += `&usuarioId=${usuarioId}`
    }

    if (query != '') {
      url += '?' + query.substring(1);
    }

    return this.http.get(url);
  }

}
