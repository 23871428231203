<div *ngIf="chatSelected != null" class="text-center border-bottom">

  <div *ngIf="chatSelected.profilePicture" class="mb-2">
    <img src="{{chatSelected.profilePicture}}" class="rounded-circle avatar-lg img-thumbnail" alt="">
  </div>

  <div *ngIf="!chatSelected.profilePicture">
    <img src="assets/images/semimagem.jpg" class="rounded-circle avatar-lg img-thumbnail" alt="">
  </div>

  <h5 class="font-size-16 mb-1 text-truncate">{{chatSelected.name | translate}}</h5>
</div>

<p-accordion>

  <div *ngIf="load" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>

  <p-accordionTab *ngIf="chatSelected != null" header="Departamento e responsável">
    <div *ngIf="dropdownDepartamentos.length > 0 && direitoService.can('ALTERAR_ATENDENTE_CHAT')"
      class="p-fluid mt-4 row">

      <div class="col-sm-12">
        <label>Departamento principal</label>
        <p-dropdown class="p-inputtext-sm dropdown-custom" filter="true" [options]="dropdownDepartamentos"
          [(ngModel)]="departamentoId" (onChange)="updateDepartamentoChat()"></p-dropdown>
      </div>


      <div class="col-sm-12">
        <label for="lastname2">Responsável principal</label>

        <p-progressSpinner *ngIf="loadingUsers" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
          strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>

        <p-dropdown *ngIf="!loadingUsers" class="p-inputtext-sm dropdown-custom" filter="true" [options]="dropdownUsers"
          [(ngModel)]="usuarioId" (onChange)="updateUsuario()"></p-dropdown>
      </div>


      <div class="col-sm-12 mt-4">
        <label>Outros Departamentos</label>
        <p-multiSelect class="p-inputtext-sm multiselect-custom" filter="true" [options]="dropdownOutrosDepartamentos"
          [(ngModel)]="outros_departamentos" (onChange)="updateDepartamentosChat()"></p-multiSelect>
      </div>

      <div class="col-sm-12">
        <label>Outros Responsáveis</label>

        <p-multiSelect class="p-inputtext-sm multiselect-custom" filter="true" [options]="dropdownOtherUsers"
          [(ngModel)]="outros_responsaveis" (onChange)="updateResponsaveisChat()"></p-multiSelect>
      </div>
    </div>
  </p-accordionTab>

  <p-accordionTab header="Dados do contato" [selected]="true">
    <div class="p-2" style="height: calc(100vh - 500px); overflow: auto;">

      <div *ngFor="let item of camposPessoaChat" class="mb-2">
        <p class="text-muted mb-1"><b>{{item['campo']}}</b></p>

        <input [disabled]="!item.editavel" *ngIf="item.tipo == 'CHECKBOX'" type="checkbox" class="form-check-input"
          id="customCheck2" (change)="saveDadoPessoa(item)" [(ngModel)]="item.value">

        <input [disabled]="!item.editavel" *ngIf="item.tipo == 'STRING'" pInputText
          class="form-control p-inputtext-sm input-custom" [(ngModel)]="item.value" (blur)="saveDadoPessoa(item)" />

        <p-dropdown *ngIf="item.tipo == 'DROPDOWN'" appendTo="body" [disabled]="!item.editavel" [filter]="true"
          class="p-fluid dropdown-custom" placeholder="Selecione" [options]="item.opcoes" [(ngModel)]="item.value"
          (onChange)="saveDadoPessoa(item)"></p-dropdown>

        <p-calendar *ngIf="item.tipo == 'DATE'" [disabled]="!item.editavel" class="p-inputtext-sm p-fluid"
          dateFormat="dd/mm/yy" [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)"></p-calendar>

        <p-inputNumber *ngIf="item.tipo == 'CURRENCY'" disabled="!item.editavel" class="p-inputtext-sm p-fluid"
          [(ngModel)]="item.value" inputId="value" mode="currency" currency="BRL" (onChange)="saveDadoPessoa(item)">
        </p-inputNumber>

        <p-inputNumber *ngIf="item.tipo == 'NUMBER'" disabled="!item.editavel" class="p-inputtext-sm p-fluid"
          inputId="integeronly" [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)">
        </p-inputNumber>

        <ul *ngIf="item.tipo == 'TABLE'" class="list-group">
          <li *ngFor="let row of item.opcoes" class="list-group-item">
            <div class="row">
              <div class="col-6 mt-2">
                {{row.label}}
              </div>

              <div class="p-fluid col-6">
                <input class="form-control p-inputtext-sm input-custom" [(ngModel)]="row.opcaoValorPessoa.value"
                  (change)="saveOpcaoValuePessoa(row)" pInputText>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </p-accordionTab>

  <p-accordionTab header="Tags">
    <div class="p-fluid">
      <div class="col-md-12">
        <p-dropdown appendTo="body" class="p-inputtext-sm dropdown-custom" [(ngModel)]="tag" filter="true"
          [options]="dropdownTags" placeholder="Adicionar tag" optionLabel="descricao" (onChange)="selectTag()">
        </p-dropdown>
      </div>

      <div class="row d-flex p-2 mt-2" style="overflow: auto;">
        <div *ngFor="let item of tagsSelected; let i = index" class="col-12 chat_tag_item rounded mr-2 mb-2"
          style="color:#007bff; background:#ffffff;margin-right: 5px;height: 40px;">

          <div class="pointer delete_chat_tag" data-chat-id="6408e91f7bc8dd798c230d35"
            data-tag-text=" {{item.descricao}}" id="tagDiv" (mouseout)="mostrarIcone = false">
            {{item.descricao}}<i style="    font-size: 12px;
                        border: 1px solid;
                        border-radius: 8px;
                        margin-left: 3px;
                        padding: 3px; cursor: pointer; color: red;" class="pi pi-times" (click)="removeTag(item)"></i>
          </div>
        </div>
      </div>

      <!-- <p-panel>
                <ng-template pTemplate="header">
                    <p-dropdown appendTo="body" class="p-inputtext-sm ml-2" [(ngModel)]="tag" filter="true"
                        [options]="dropdownTags" placeholder="Adicionar tag" optionLabel="descricao"
                        (onChange)="selectTag()"></p-dropdown>
                </ng-template>


                <div class="row d-flex">
                    <div *ngFor="let item of tagsSelected; let i = index" class="col-12 chat_tag_item rounded mr-2 mb-2"
                        style="color:#007bff; background:#ffffff;margin-right: 5px;">

                        <div class="pointer delete_chat_tag" data-chat-id="6408e91f7bc8dd798c230d35"
                            data-tag-text=" {{item.descricao}}" id="tagDiv" (mouseout)="mostrarIcone = false">
                            {{item.descricao}}<i style="    font-size: 12px;
                            border: 1px solid;
                            border-radius: 8px;
                            margin-left: 6px;
                            padding: 3px; cursor: pointer; color: red;" class="pi pi-times"
                                (click)="removeTag(item)"></i>
                        </div>
                    </div>
                </div>
            </p-panel> -->
    </div>
  </p-accordionTab>

  <p-accordionTab header="Resumo do ultimo atendimento">
    <textarea *ngIf="chatSelected?.resumoUltimoAtendimento" rows="30" cols="100" pInputTextarea
      [(ngModel)]="chatSelected.resumoUltimoAtendimento"></textarea>

  </p-accordionTab>

</p-accordion>
