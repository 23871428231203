import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
   private atualizaInstanciasOfflineSubject = new Subject<void>();

  constructor() { }

  atualizaInstanciasOffline$ = this.atualizaInstanciasOfflineSubject.asObservable();
  atualizaInstanciasOffline() {
    this.atualizaInstanciasOfflineSubject.next();
  }
}
