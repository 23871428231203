<div class="container">


  <div class="p-fluid  row mt-2" style="padding: 0;
    margin: 0;">

    <div class="col-lg-12" style="padding: 0px;">
      <div class="card">

        <p-toolbar>
          <div class="p-toolbar-group-left">
          </div>

          <div class="p-toolbar-group-right d-flex">

            <button type="button" *ngIf="direitoInserir != undefined"
              class="btn btn-success waves-effect waves-light me-1" (click)="save()"><i class="fa fa-check-circle"></i>
              Salvar</button>
          </div>
        </p-toolbar>
      </div>

    </div>



    <div *ngIf="false" class="col-lg-12">
      <div class="card">
        <div class="card-body row" style="background-color: #edeff1;">
          <div class="d-flex justify-content-start ofset-6 col-md-2 mb-12">
            <div class="text-md-start mt-3 mt-md-0">

              <button type="button" *ngIf="direitoInserir != undefined"
                class="btn btn-success waves-effect waves-light me-1" (click)="save()"><i
                  class="fa fa-check-circle"></i> Salvar</button>

              <a *ngIf="direitoInserir == undefined" class="btn btn-secondary"><i class="fa fa-check-circle"></i>
                Salvar</a>
            </div>
            <div *ngIf="direitoInserir == undefined" class="text-md-start mt-3 mt-md-0">
              <small class="badge badge-soft-danger me-1 rounded-pill">Usuario não tem permissão para
                realizar esta funcionalidade</small>
            </div>

          </div>


        </div>
      </div> <!-- end card -->

    </div>
  </div>





  <div *ngIf="!loading" class="card">
    <form [formGroup]="form">
      <ul class="list-group">
        <!-- <li class="list-group-item">
                    <div class="row">
                        <div class="col-6 mt-2">
                            <label> Pasta de arquivos</label>
                        </div>

                        <div class="p-fluid col-6">
                            <input pInputText />
                        </div>
                    </div>
                </li> -->

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Marcar status de aberto para novo chat
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="status_aberto_novo_chat"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Marcar status de EM ANDAMENTO quando o USUÁRIO do sistema responder o chat
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="status_andamento_usuario_responder_chat">
              </p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Marcar status de EM ATENDIMENTO quando o BOT do sistema responder o chat
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="status_andamento_bot_responder_chat">
              </p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Transferir as mensagens ao alterar o consultor do chat
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="transferir_mensagem_direcionar_chat">
              </p-inputSwitch>
            </div>
          </div>
        </li>

        <!-- <li class="list-group-item">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Etapa inicial da negociação para novos atendimentos
                        </div>

                        <div class="p-fluid col-4">
                            <p-dropdown formControlName="etapa_negociacao_novo_atendimento" [options]="dropdownOportunidadeEtapa"></p-dropdown>
                        </div>
                    </div>
                </li> -->

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Permite enviar mensagem fora da plataforma
              <small class="badge badge-soft-danger me-1 rounded-pill">Essa configuração só
                funcionará
                se
                cada consultor possuir um canal de atendimento</small>
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="permite_enviar_mensagem_externa"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Manter o vendedor do ultimo atendimento
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="manter_vendedor_ultimo_atendimento"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Direcionar para um atendente de forma automatica <small
                class="badge badge-soft-danger me-1 rounded-pill">O sistema manterá o ultimo atendente
                caso a configuração acima esteja marcada!</small>
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="direcionar_vendedor_automatico"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Habilitar agenda
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="modulo_agenda"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Chat Interno
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="chat_interno"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Permitir abrir conversa para contato que ja foi atendido?
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="permitir_nova_conversa_contato_atendido"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Habilitar visão 360
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="visao_360"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Habilitar modulo carteira
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="modulo_carteira"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              habilitar opção de criação de evento na agenda a partir de uma anotação interna
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="evento_anotacao_interna"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              habilitar vínculo de departamento com canal de atendimento
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="habilitar_vinculo_departamento_canal"></p-inputSwitch>
            </div>
          </div>
        </li>


        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Notificar ao receber uma mensagem na conversa
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="notificar_mensagem_recebida_chat"
                (onChange)="changePermissaoNotificacaoMensagem()"></p-inputSwitch>
            </div>
          </div>
        </li>


        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Desarquivar conversa ao receber mensagem do cliente
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="desarquivar_chat_receber_mensagem"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Salvar arquivos no s3
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="s3"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Enviar pesquisa de avaliação do atendimento após fechar o chat
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="enviar_avaliacao_atendimento"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li *ngIf="form.get('enviar_avaliacao_atendimento').value == true" class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Mensagem avaliação atendimento
            </div>

            <div class="p-fluid col-3">
              <textarea pInputTextarea formControlName="mensagem_avaliacao_atendimento"
                class="p-inputtext-sm"></textarea>
            </div>

          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Enviar protocolo de atendimento
              <br>
              <small class="badge badge-soft-danger me-1 rounded-pill">O protocolo é formado por data,
                hora e código do atendimento ex: 010120221540-1</small>
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="enviar_protocolo_atendimento"></p-inputSwitch>
            </div>

          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              habilitar e-commerce
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="habilita_ecommerce"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              URL do e-commerce
            </div>

            <div class="p-fluid col-6">
              <input [disabled]="form.get('habilita_ecommerce').value" formControlName="url_ecommerce"
                class="p-inputtext-sm" pInputText>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Disponibilizar chat para outro consultor após
            </div>

            <div class="p-fluid col-1">
              <input type="number" min="0" class="p-inputtext-sm" formControlName="tempo_disponibilizar_chat"
                pInputText>
            </div>

            <div class="p-fluid col-3">
              minutos da ultima mensagem enviada pelo cliente.
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Vincular chat a um atendente após
              <small class="badge badge-soft-danger me-1 rounded-pill">Essa configuração só
                é válida para clientes que estão aguardando atendimento</small>
            </div>

            <div class="p-fluid col-1">
              <input type="number" min="0" class="p-inputtext-sm" formControlName="tempo_direcionar_chat" pInputText>
            </div>

            <div class="p-fluid col-3">
              minutos após a ultima interação
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Encerrar chat após
            </div>

            <div class="p-fluid col-1">
              <input type="number" min="1" class="p-inputtext-sm" formControlName="tempo_encerrar_chat" pInputText>
            </div>

            <div class="p-fluid col-1">
              horas.
            </div>
          </div>
        </li>



        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Visualização Padrão das oportunidades
            </div>

            <div class="p-fluid col-2">
              <p-dropdown formControlName="visualizacao_padrao_oportunidade" [options]="dropDownVisualizacao"
                class="p-inputtext-sm">
              </p-dropdown>
            </div>
          </div>
        </li>



        <!-- <li class="list-group-item">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Finalizar chat após a classificação
                            <br>
                            <small class="badge badge-soft-danger me-1 rounded-pill">Permite finalizar o chat somente
                                após a classificação do atendimento.</small>
                        </div>

                        <div class="p-fluid col-1">
                            <p-inputSwitch formControlName="finalizar_chat_apos_classificar"></p-inputSwitch>
                        </div>

                    </div>
                </li> -->

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Visualização Padrão das oportunidades
            </div>

            <div class="p-fluid col-2">
              <p-dropdown formControlName="integrador_whatsapp" class="p-inputtext-sm"
                [options]="dropdownIntegracaoWhatsapp">
              </p-dropdown>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Quantidade de mensagens enviadas por disparo (Campanha);
            </div>

            <div class="p-fluid col-1">
              <input type="number" min="0" formControlName="quantidade_disparo_campanha" class="p-inputtext-sm"
                pInputText>
            </div>
          </div>
        </li>



        <!-- <li class="list-group-item">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Logo da empresa
                        </div>

                        <div class="p-fluid col-4">

                            <div class="p-inputgroup">
                                <i class="mr-2 pi pi-upload"
                                    style="cursor: pointer; margin-right: 5px; margin-top: 5px;"
                                    pTooltip="Selecione o arquivo"></i>
                                <input type="file" style="display: none;">
                                <input formControlName="logo_empresa_link" class="ml-2" pInputText>
                            </div>


                        </div>
                    </div>
                </li> -->

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Considerar typebot de:
            </div>

            <div class="p-fluid col-2">
              <p-dropdown formControlName="conf_typebot" placeholder="Selecione" class="p-inputtext-sm"
                [options]="dropdownConfTypebot">
              </p-dropdown>
            </div>
          </div>
        </li>

        <!-- <li class="list-group-item">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Habilitar horario de funcionamento da empresa
                        </div>

                        <div class="p-fluid col-1">
                            <p-inputSwitch formControlName="habilitar_horario_funcionamento"></p-inputSwitch>
                        </div>
                    </div>
                </li> -->

        <li *ngIf="form.get('habilitar_horario_funcionamento').value" class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Mensagem personalizada para quando a empresa estiver fechada.
            </div>

            <div class="p-fluid col-1">
              <textarea [rows]="5" [cols]="60" pInputTextarea formControlName="mensagem_funcionamento"></textarea>
            </div>
          </div>
        </li>

        <!-- <li class="list-group-item">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Habilitar RD station
                        </div>

                        <div class="p-fluid col-1">
                            <p-inputSwitch formControlName="habilitar_integracao_rd_station"></p-inputSwitch>
                        </div>
                    </div>
                </li> -->

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              habilitar IA

            </div>
            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="habilitar_ia"></p-inputSwitch>
            </div>

          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Token OpenAi
            </div>

            <div class="p-fluid col-6">
              <input type="password" pPassword formControlName="token_openai" feedback="false" />
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Nome da IA
            </div>

            <div class="p-fluid col-6">
              <input pInputText formControlName="nome_ia" />
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Tipo de integração OpenAI
            </div>

            <div class="p-fluid col-6">
              <p-dropdown formControlName="tipo_integracao_openai" placeholder="Selecione" class="p-inputtext-sm"
                [options]="dropdownTipoIntegracaoOpenAi">
              </p-dropdown>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Habilitar transcrição de texto para audios recebidos

            </div>
            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="transcrever_audio"></p-inputSwitch>
            </div>

          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Habilitar resumo do dia
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="habilitar_resumo_dia"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Faculdade Estacio
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="faculdade_estacio"></p-inputSwitch>
            </div>
          </div>
        </li>

        <li *ngIf="form.get('habilitar_integracao_rd_station').value" class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Client ID Rd Station:
            </div>

            <div class="p-fluid col-6">
              <input type="password" pPassword formControlName="client_id_rd_station" feedback="false" />
            </div>
          </div>
        </li>

        <li *ngIf="form.get('habilitar_integracao_rd_station').value" class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Secret key Rd Station:
            </div>

            <div class="p-fluid col-6">
              <input type="password" pPassword formControlName="secret_key_rd_station" feedback="false" />
            </div>
          </div>
        </li>


        <li *ngIf="form.get('habilitar_integracao_rd_station').value" class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              URL Callback RD Station
            </div>

            <div class="p-fluid col-6">
              <input formControlName="url_callback_rd_station" class="p-inputtext-sm" pInputText>
            </div>
          </div>
        </li>

        <li *ngIf="showIntegracaoEleitto" class="list-group-item">
          <div class="row">
            <div class="col-6 mt-2">
              Integrar com o eleitto
            </div>

            <div class="p-fluid col-1">
              <p-inputSwitch formControlName="integracao_eleitto"></p-inputSwitch>
            </div>
          </div>
        </li>




      </ul>
    </form>

    <!-- <div *ngIf="form.get('habilitar_horario_funcionamento').value">
            <button type="button" *ngIf="direitoInserir != undefined"
                class="btn btn-success waves-effect waves-light me-1" (click)="addHorarioFuncionamento()"
                style="margin: 10px;"><i class="fa fa-check-circle"></i> Adicionar</button>

            <ul class="list-group">
                <li class="list-group-item">
                    <div *ngFor="let item of arrayHorarioFuncionamento" class="row">
                        <div class="col-6 mt-2">
                            <p-dropdown [(ngModel)]="item.dia_semana" placeholder="Selecione o dia da semana"
                                class="p-inputtext-sm" [options]="arrayDiasSemana">
                            </p-dropdown>
                        </div>

                        <div class="p-fluid col-1" style="margin-top: 7px;">
                            <p-calendar [(ngModel)]="item.abertura" [showTime]="true" [showSeconds]="true"
                                class="p-inputtext-sm" [showSeconds]="false" [timeOnly]="true"
                                inputId="time"></p-calendar>
                        </div>

                        <div class="col-1" style="margin-top: 12px;">
                            Até.
                        </div>

                        <div class="p-fluid col-1" style="margin-top: 7px;">
                            <p-calendar [(ngModel)]="item.fechamento" [showTime]="true" [showSeconds]="true"
                                class="p-inputtext-sm" [showSeconds]="false" [timeOnly]="true"
                                inputId="time"></p-calendar>
                        </div>

                        <div class="col-1">
                            <button type="button" class="btn btn-danger waves-effect waves-light me-1"
                                (click)="removeHorarioFuncionamento(item)" style="margin: 3px;"><i
                                    class="ri-close-circle-line"></i>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div> -->
  </div>

  <div *ngIf="loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>


</div>
