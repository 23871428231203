import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { ArquivoService } from 'src/app/core/services/arquivo.service';
import { BaseConhecimentoService } from 'src/app/core/services/base-conhecimento.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';

@Component({
  selector: 'app-base-conhecimento-ia',
  templateUrl: './base-conhecimento-ia.component.html',
  styleUrls: ['./base-conhecimento-ia.component.css']
})
export class BaseConhecimentoIaComponent implements OnInit {
  arquivoUpload: any[] = [];
  @ViewChild('fubauto') fubauto!: FileUpload;
  dropdownDepartamentos: any[] = [];
  basesList: any[] = [];
  page: string = 'view';
  form: FormGroup
  loadingFiles: boolean = false;
  modalContexto: boolean = false;
  contextoArquivo: string;
  loadingSave: boolean = false;

  constructor(
    private fb: FormBuilder,
    private arquivoService: ArquivoService,
    private departamentoService: DepartamentoService,
    private baseConhecimentoService: BaseConhecimentoService,
    private http: HttpClient
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      id: [null],
      ativo: [true],
      descricao: [null, Validators.required],
      conteudo: [null],
      instrucao: [null],
      openai_assistente_id: [null],
      departamentos: [[]],
      arquivos: [[]]
    });

    this.listDepartamentos();
    this.listAll();
  }

  listDepartamentos() {
    this.departamentoService.index().subscribe(json => {
      this.dropdownDepartamentos = json.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  myUploader(event) {
    this.fubauto.clear();

    let formData = new FormData();

    event.files.map(file => {
      if (!file?.id) {
        console.log(file);
        formData.append('myFiles[]', file);
      }
    })

    this.loadingFiles = true;
    this.arquivoService.uploadMulti(formData).subscribe(arquivosResponse => {
      this.loadingFiles = false;
      let arquivoList = this.form.get('arquivos').value || [];
      arquivosResponse.map(item => {
        arquivoList.push(item);
      })
      this.form.get('arquivos').setValue(arquivoList);
    }, err => {
      this.loadingFiles = false;
    });
  }

  listAll() {
    this.baseConhecimentoService.index().subscribe(json => {
      this.basesList = json;
    });
  }

  save() {
    let form = this.form.value;
    let departamentos = form.departamentos.map(item => { return { departamento_id: item.value } });
    let contextos: any[] = [];

    if (form.arquivos != null) {
      form.arquivos.map(item => {
        if (item) {
          contextos.push({
            id: item?.id || null,
            conteudo: null,
            arquivo_id: item.id
          })
        }
      })
    }

    contextos.push({ conteudo: form.conteudo, arquivo_id: null });

    let body = {
      base_conhecimento: {
        openai_assistente_id: form.openai_assistente_id,
        departamentos,
        descricao: form.descricao,
        ativo: form.ativo || false,
        instrucao: form.instrucao,
        contextos
      }
    }

    this.loadingSave = true;
    this.baseConhecimentoService.save(body, form.id).subscribe(json => {
      this.loadingSave = false;
      this.listAll();
      this.page = 'view';
    }, err => {
      this.loadingSave = false;
    })
  }

  add() {
    this.form.reset();
    this.form.get('ativo').setValue(true);
    this.page = 'form';
  }

  show(id) {
    this.form.reset();
    this.baseConhecimentoService.show(id).subscribe(json => {
      let contextoTexto = json.contextos.find(contexto => { return contexto.arquivo_id == null });
      let arquivos = json.contextos.filter(contexto => { return contexto.arquivo !== null });

      this.form.get('id').setValue(json.id);
      this.form.get('descricao').setValue(json.descricao);
      this.form.get('ativo').setValue(json.ativo);
      this.form.get('conteudo').setValue(contextoTexto?.conteudo || null);
      this.form.get('openai_assistente_id').setValue(json.openai_assistente_id);
      this.form.get('instrucao').setValue(json.instrucao);
      this.form.get('departamentos').setValue(json.departamentos.map(item => { return { value: item.departamento.id, label: item.departamento.nome } }));

      let arquivosUpados = [];
      arquivos.map(item => {
        let obj: any = {};
        obj = item.arquivo;
        obj['baseConhecimentoId'] = item.id;
        arquivosUpados.push(obj);
        this.form.get('arquivos').setValue(arquivosUpados);
      })

      // this.form.get('arquivos').setValue(arquivos.map(item => { return item.arquivo }));
      this.page = 'form';
    })
  }

  downloadAndUploadFile(fileUrl) {
    let body = {
      url: fileUrl
    }

    this.loadingFiles = true;
    this.arquivoService.downloadFileToS3(body).subscribe(json => {
      this.loadingFiles = false;
      let arquivoList = this.form.get('arquivos').value || [];
      arquivoList.push(json);
      this.form.get('arquivos').setValue(arquivoList);
    })
  }

  showContexto(arquivo) {
    this.modalContexto = true;
    let request = 'arquivoId=' + arquivo.id;
    this.baseConhecimentoService.findContexto(request).subscribe(json => {
      this.contextoArquivo = json[0].conteudo;
    })
  }

  removeFile(item) {
    let arquivos = this.form.get('arquivos').value;
    arquivos = arquivos.filter(arquivo => { return arquivo != item });
    this.form.get('arquivos').setValue(arquivos);

    if (this.form.get('id').value != null) {
      this.baseConhecimentoService.removeFileContexto(item.id).subscribe(json => {
        
      });
    }
  }
}

