import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppIconService {

  constructor() { }

  setFavicon(iconUrl: string): void {
    const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");

    console.log('link', link);

    if (link) {
      link.href = iconUrl;
    } else {
      console.log('link', link);
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = iconUrl;
      document.head.appendChild(newLink);
    }
  }
}
