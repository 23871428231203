import { Component } from '@angular/core';
import { DireitoService } from 'src/app/core/services/direito.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
  conf;

  usuario;

  constructor(
    public direitoService:DireitoService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('user'));
    this.conf = JSON.parse(localStorage.getItem('configuracao'));
  }
}
