<div class="row">
  <p-fullCalendar [events]="events" [options]="options"></p-fullCalendar>
</div>


<p-dialog header="Detalhe do evento" [(visible)]="modalDetalhe" [modal]="true" [style]="{width: '60vw'}"
  [draggable]="false">

  <div *ngIf="eventoDetalhe != null" class="col-md-12">
    <p>Titulo: {{eventoDetalhe?.title}}</p>
    <br />

    <div id="mensagem" [innerHtml]="eventoDetalhe.description"></div>

  </div>



  <ng-template pTemplate="footer">
    <div>
      <button (click)="modalDetalhe=false" label="Fechar" type="button"
        class="btn btn-danger waves-effect waves-light me-1">Fechar</button>
    </div>
  </ng-template>
</p-dialog>