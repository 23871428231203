import { Component, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { contacts } from './data';
import { Contacts } from './contacts.model';
import { TranslateService } from '@ngx-translate/core';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ChatService } from 'src/app/core/services/chat.service';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
/**
 * Tab-contacts component
 */
export class ContactsComponent implements OnInit {
  @ViewChild('content', { static: true }) content: TemplateRef<any>;


  @Output() onSelect: EventEmitter<any> = new EventEmitter();



  contacts: any[] = [];
  contactsList: any[] = [];
  totalRecords = 300;
  filterValue;
  filterType = 'name';
  modalUpdateContacts = false;

  page = 1;

  form: FormGroup;

  dropDownTipo = [];

  urlS3 = environment.urlS3;
  contactSelected;

  loading = false;
  dropdownIntegracaoWhatsapp = [];
  instancia;
  modalCadastro = false;
  dropDownTag = [];
  dropdownTipo = [
    { value: 'FISICA', label: 'Pessoa Fisica' },
    { value: 'JURIDICA', label: 'Pessoa Juridica' },
  ]

  constructor(
    private modalService: NgbModal,
    public translate: TranslateService,
    private pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private chatService: ChatService,
    private integracaoWhatsappService: IntegracaoWhatsappService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      pessoa: this.formBuilder.group({
        id: [null],
        ativo: [true],
        nome: [null, Validators.required],
        tipo: ['FISICA'],
        telefone_principal: [null],
        celular_principal: [null],
        email_principal: [null],
        cliente: [true],
        funcionario: [null],
        fornecedor: [null],
        black_list: [false],
      }),
      fisica: this.formBuilder.group({
        nascimento: [null],
        apelido: [null],
        cpf: [null],
        rg: [null],
      }),
      juridica: this.formBuilder.group({
        razao_social: [null],
        cnpj: [null],
        ie: [null],
      }),
      endereco: this.formBuilder.group({
        cep: [null],
        logradouro: [null],
        bairro: [null],
        municipio: [null],
        complemento: [null],
        uf: [null],
        numero: [null],
      }),
      tag: this.formBuilder.group({
        tags: [] = [],
      }),
    })

    this.lazyLoadContacts();
    this.listAllIntegracaoWhatsapp();

    // const sorted = contacts.sort((a, b) => a.name > b.name ? 1 : -1);

   

    // const grouped = sorted.reduce((groups, contact) => {
    //   const letter = this.translate.instant(contact.name).charAt(0);
    //   groups[letter] = groups[letter] || [];
    //   groups[letter].push(contact);

    //   return groups;
    // }, {});

   

    // contacts list
    // this.contactsList = Object.keys(grouped).map(key => ({ key, contacts: grouped[key] }));

    
  }

  showModalCadastro() {
    this.modalCadastro = true;
  }

  listAllIntegracaoWhatsapp() {
    this.integracaoWhatsappService.index().subscribe(json => {
      this.dropdownIntegracaoWhatsapp = json.map(item => { return { value: item.id, label: `${item.nome} - ${item.whatsapp}` } })
    })
  }

  verificarExistenciaImagem(url) {
    return fetch(url, { method: 'HEAD' })
      .then(response => {
        return true;
      })
      .catch(error => {
        return false;
      });
  }

  lazyLoadContacts(event: any = null) {
    let page = 1;

    if (event != null) {
      page = (event['first'] / event['rows']) + 1;
    }

    let body: any = {
      // tipo: 'FISICA'
    };

    if (this.filterValue != null) {
      this.contacts = []
      this.contactsList = []
      if (this.filterType == 'name') {
        body = { ...body, nome: this.filterValue }
      } else {
        body = { ...body, celular_principal: this.filterValue }
      }
    }

    this.loading = true;

    this.pessoaService.filtroPaginado(page, body, 100).subscribe(json => {
      try {
        this.loading = false;

        this.totalRecords = json.total;

        json.data.map(c => {
          let x = this.contacts.find(z => z.whatsapp == c.celular_principal)
          if(x == undefined){
            this.contacts.push({
              id: c.id, name: c.nome, profilePicture: c.foto_perfil, whatsapp: c.celular_principal 
            })
          }
        
        })

        
        this.contacts.forEach(element => {
          element.name = element.name || element.whatsapp;
        })

        const sorted = this.contacts.sort((a, b) => a.name > b.name ? 1 : -1);

        const grouped = sorted.reduce((groups, contact) => {
          if (contact.name != "") {
            let letter = this.translate.instant(contact.name).charAt(0);
            if (/^[a-zA-Z]$/.test(letter)) {
              letter = letter.toUpperCase();
            }
            groups[letter] = groups[letter] || [];
            groups[letter].push(contact);
          }

          return groups;
        }, {});

        this.contactsList = Object.keys(grouped).map(key => ({ key, contacts: grouped[key] }));

       
      } catch (error) {
       
      }
    })
  }

  removeDuplicates(data) {
    return data.map(group => {
        const uniqueContacts = [];
        const whatsappSet = new Set();

        group.contacts.forEach(contact => {
            if (contact.whatsapp && !whatsappSet.has(contact.whatsapp)) {
                whatsappSet.add(contact.whatsapp);
                uniqueContacts.push(contact);
            }
        });

        return {
            ...group,
            contacts: uniqueContacts
        };
    });
}

  select(item) {
    this.contactSelected = item;
    this.onSelect.emit(item);
  }

  getStyle(item) {
    try {
      if (item.id == this.contactSelected.id) {
        return { 'background-color': ' white', 'border-radius': '21px' }
      } else {
        return {}
      }
    } catch (error) {
      return {}
    }
  }


  /**
   * Contacts modal open
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openContactsModal(content) {
    this.modalService.open(content, { centered: true });
  }

  save() {
    let form = this.form.value;

    let body = {
      pessoa: {
        nome: form.pessoa.nome,
        celular_principal: form.pessoa.celular_principal,
        tipo: form.pessoa.tipo,
      },
      pessoaFisica: {
        nascimento: form.fisica.nascimento,
        isCPF: false
      },
      pessoaJuridica: form.juridica,
      endereco: form.endereco,
      tags: []
    }

    this.pessoaService.save(body, form.pessoa.id).subscribe(json => {
      this.modalCadastro = false;
      this.modalService.dismissAll();
      this.filterValue = form.pessoa.nome
      this.lazyLoadContacts();
    })
  }

  show(id) {
    this.pessoaService.show(id).subscribe(contact => {

      this.form.get('pessoa.id').setValue(contact.id);
      this.form.get('pessoa.ativo').setValue(contact.ativo);
      this.form.get('pessoa.nome').setValue(contact.nome);
      this.form.get('fisica.apelido').setValue(contact?.fisica?.apelido);
      this.form.get('pessoa.celular_principal').setValue(contact.celular_principal);

      if (contact.juridica != null) {
        this.form.get('juridica').setValue(contact.juridica);
      }

      if (contact.endereco != null) {
        this.form.get('endereco').setValue(contact.endereco);
      }

      let nascimento;
      try {
        nascimento = new Date(contact?.fisica?.nascimento);
      } catch (error) {
        nascimento = null;
      }

      this.form.get('fisica.nascimento').setValue(nascimento);

      this.openContactsModal(this.content)
    })
  }

  openUpdateContacts() {
    this.instancia = null;
    this.modalUpdateContacts = true;
  }

  updateListContacts() {
    this.pessoaService.updateContactsInstance(this.instancia).subscribe(json => {
      this.modalUpdateContacts = false;
      this.lazyLoadContacts();
    })
  }

  buscaCEP() {
    let cep = this.form.get("endereco.cep").value;

    try {
      cep = parseInt(cep.replace(/[^0-9]/g, ""));
    } catch (error) {
      cep = this.form.get("endereco.cep").value;
    }

    if (cep == null) {
      return;
    }

    this.http
      .get(`https://viacep.com.br/ws/${cep}/json`)
      .toPromise()
      .then((response) => {
        response["municipio"] = response["localidade"];
        response["cep"] = cep;
        this.form.get("endereco").patchValue(response);
      });
  }

}
