<form [formGroup]="form" class="p-fluid row g-2 p-3">

  <div class="col-sm-12">
    <label for="lastname2">Login Sales Force</label>
    <input pInputText class="form-control p-inputtext-sm" formControlName="login_sales_force" />
  </div>

  <div class="col-sm-12">
    <label for="lastname2">Senha Sales Force</label>
    <p-password formControlName="senha_sales_force" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte"
      class="p-inputtext-sm">
    </p-password>
  </div>

  <div class="col-sm-12">
    <label for="lastname2">Login Conexão Polos</label>
    <input pInputText class="form-control p-inputtext-sm" formControlName="login_conexao_polos" />
  </div>

  <div class="col-sm-12">
    <label for="lastname2">Senha Conexão Polos</label>
    <p-password formControlName="senha_conexao_polos" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte"
      class="p-inputtext-sm">
    </p-password>
  </div>

  <div *ngIf="loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}"
      styleClass="custom-spinner" strokeWidth="8" fill="var(--surface-ground)"
      animationDuration=".5s"></p-progressSpinner>
  </div>

  <button *ngIf="!loading" type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
    class="pi pi-save"></i></button>

</form>