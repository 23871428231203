<p-dialog header="Resumo do dia" [(visible)]="modal" [style]="{ height: '60vh', width: '50vw'}" (onHide)="close()">
  <div class="card">
    <div class="row p-fluid">
      <div class="col-sm-3">
        <div class="card card-dashboard">

          <div class="card-body">
            <div class="float-end">
              <i class="pi pi-whatsapp widget-icon" style="color: rgb(143, 197, 157); font-size: 30px;"></i>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Total</h5>
            <h3 class="mt-3 mb-3">{{cards?.count}}</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="card card-dashboard">
          <div class="card-body">
            <div class="float-end">
              <i class="pi pi-comment widget-icon" style="color: rgb(219, 219, 118); font-size: 30px;"></i>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Aguardando</h5>
            <h3 class="mt-3 mb-3">{{cards?.countAguardando}}</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="card card-dashboard">
          <div class="card-body">
            <div class="float-end">
              <i class="pi pi-comment widget-icon" style="color: rgb(219, 219, 118); font-size: 30px;"></i>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Em andamento</h5>
            <h3 class="mt-3 mb-3">{{cards?.countAndamento}}</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="card card-dashboard">
          <div class="card-body">
            <div class="float-end">
              <i class="pi pi-check widget-icon" style="color: rgb(80, 147, 247); font-size: 30px;"></i>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Finalizados</h5>
            <h3 class="mt-3 mb-3">{{cards?.countFinalizado}}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="form-control">
      <label>Valor total de vendas</label>
      <p-inputNumber class="form-control p-inputtext-sm" [(ngModel)]="valorDia" mode="currency" currency="BRL">
      </p-inputNumber>
    </div>
  </div>

  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="close()"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
        class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>