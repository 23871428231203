<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['usuario.name']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-right d-flex">
        <button type="button" class="btn btn-primary btn mr-2" (click)="create()" ngbTooltip="Criar nova demanda"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr c>
      <th>Data</th>
      <th>Usuario</th>
      <th>Status</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td>{{item.created_at}}</td>
      <td>{{item.usuario?.name}}</td>
      <td>{{getStatusLabel(item.status)}}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>


<p-dialog header="Demanda do bot" [(visible)]="modal" [style]="{width: '35vw'}">

  <form *ngIf="showInputToken && loading == false" [formGroup]="form">
    <div class="col-md-12">
      <label>Informe o código recebido via SMS</label>
      <input pInputText class="form-control" formControlName="token" />
    </div>
  </form>

  <div *ngIf="!showInputToken && loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>



  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button [disabled]="form.invalid" type="button" class="btn btn-success btn mr-2" (click)="sendToken()" ngbTooltip="Enviar"><i
        class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>