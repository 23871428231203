import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfiguracaoEstacioAutomacaoService } from 'src/app/core/services/configuracao-estacio-automacao.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-acessos-estacio',
  templateUrl: './acessos-estacio.component.html',
  styleUrls: ['./acessos-estacio.component.css']
})
export class AcessosEstacioComponent implements OnInit {
  form: FormGroup;
  secretKey: string = environment.secretKeyBkchat
  loading:boolean = false;

  constructor(
    private fb: FormBuilder,
    private configuracaoAutomacaoEstacioService: ConfiguracaoEstacioAutomacaoService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      id: [null],
      login_sales_force: [null],
      senha_sales_force: [null],
      login_conexao_polos: [null],
      senha_conexao_polos: [null],
    });

    this.configuracaoAutomacaoEstacioService.findAcessos().subscribe(json => {
      this.form.patchValue(json);
    })
  }


  save() {
    // let senhaSalesForce = this.form.get('senha_sales_force').value;
    // let senhaConexaoPolos = this.form.get('senha_conexao_polos').value;

    // // Gera um IV aleatório
    // const iv = CryptoJS.lib.WordArray.random(16);

    // const senhaSalesForceCryp = CryptoJS.AES.encrypt(senhaSalesForce, CryptoJS.enc.Utf8.parse(this.secretKey), {
    //   iv: iv,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7
    // }).toString();

    // const senhaConexaoPolosCryp = CryptoJS.AES.encrypt(senhaConexaoPolos, CryptoJS.enc.Utf8.parse(this.secretKey), {
    //   iv: iv,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7
    // }).toString();

    // let body = {
    //   login_sales_force: this.form.get('login_sales_force').value,
    //   senha_sales_force: senhaSalesForceCryp,
    //   login_conexao_polos: this.form.get('login_conexao_polos').value,
    //   senha_conexao_polos: senhaConexaoPolosCryp,
    //   iv: iv.toString(CryptoJS.enc.Hex)
    // }

    this.loading = true;
    this.configuracaoAutomacaoEstacioService.saveAcessosEstacio(this.form.value, this.form.get('id').value).subscribe(json => {
      this.loading = false;
    })
  }

}
