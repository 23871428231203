import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseConhecimentoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'base-conhecimento');
  }


  findContexto(request): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/find/contextos`;
    if (request != undefined) {
      url += `?${request}`;
    }
    return this.http.get(url); 
  }

  removeFileContexto(arquivoId): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/contextos/arquivo/${arquivoId}`;
    return this.http.delete(url); 
  }

}
