import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanciaWhatsappComponent } from './instancia-whatsapp.component';
import { InstanciaListComponent } from './instancia-list/instancia-list.component';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';



@NgModule({
  declarations: [InstanciaWhatsappComponent, InstanciaListComponent],
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    PerfectScrollbarModule,
    DropdownModule,
    TableModule
  ],
  exports:[InstanciaWhatsappComponent, InstanciaListComponent]
})
export class InstanciaWhatsappModule { }
