import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoEstacioAutomacaoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'configuracao-automacao-estacio');
  }

  saveAcessosEstacio(body, id = null): Observable<any> {
    if (!id) {
      return this.http.post(`${this.url_base}/${this.prefixo}/acessos`, body)
    } else {
      return this.http.put(`${this.url_base}/${this.prefixo}/acessos/${id}`, body)
    }
  }

  findAcessos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/acessos`);
  }

}
