import { Component } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import { AgendaService } from '../core/services/agenda.service';

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent {
    events: any[] = []

    options: any;

    eventoDetalhe:any;

    modalDetalhe = false;

    constructor(
        private agendaService: AgendaService,
    ) { }

    ngOnInit(): void {

        this.agendaService.index().subscribe(json => {
            json.map(agenda => {

                this.events = [...this.events, {
                    id: agenda.id,
                    title: `${agenda.titulo} - (${agenda.status})`,
                    description: agenda.descricao.replace(/\n/g, '<br>'),
                    start: agenda.inicio,
                    end: agenda.inicio,
                    color: 'blue'
                }]
            })
        })

        this.options = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            defaultDate: Date.now(),
            locale: 'pt',
            locales: [ptLocale],
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            editable: true,
            dateClick: function (event) {
                console.log(event);
                // localStorage.setItem('data_agenda', event.dateStr);
            },
            eventClick: this.showEvento.bind(this),
            // eventClick: function (info) {
            //     this.showEvento(info.event.id)
            //     console.log(info.event.id);
            //     // localStorage.setItem('evento_agenda_id', info.event.id);
            // },
            eventColor: 'red',
        };

    }

    showEvento(info) {
        this.eventoDetalhe = this.events.find(evento => {return evento.id == info.event.id});
        this.modalDetalhe = true;
    }
}
