<p-tabView>
    <p-tabPanel *ngIf="direitoService.can('DIREITO_USUARIO_INSERIR') || usuario.name == 'admin'"
        header="Permissões de acesso">
        <app-access-permissions></app-access-permissions>
    </p-tabPanel>

    <p-tabPanel *ngIf="direitoService.can('CONFIGURACAO_INSERIR') || usuario.name == 'admin'" header="Parametrizações">
        <app-parameterization></app-parameterization>
    </p-tabPanel>

    <p-tabPanel header="Campos personalizados">
        <p-tabView>
            <p-tabPanel header="Pessoa">
                <app-campo-personalizado-pessoa></app-campo-personalizado-pessoa>
            </p-tabPanel>
            <p-tabPanel *ngIf="direitoService.can('CADASTRO_PRODUTO')" header="Produtos">
                <app-campo-personalizado-produto></app-campo-personalizado-produto>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>

    <p-tabPanel *ngIf="conf['faculdade_estacio'] == true" header="Recursos Estacio">

        <p-tabView>
            <p-tabPanel header="Planilha Disparo">
                <app-planilha-estacio></app-planilha-estacio>
            </p-tabPanel>

            <p-tabPanel header="Configurações do Robô">
                <app-automacao-estacio></app-automacao-estacio>
            </p-tabPanel>

            <p-tabPanel header="Senhas de Acesso">
                <app-acessos-estacio></app-acessos-estacio>
            </p-tabPanel>

            <p-tabPanel header="Assistente Conexão Polos">
                <app-assistente-conexao-polos></app-assistente-conexao-polos>
            </p-tabPanel>
        </p-tabView>


    </p-tabPanel>

    <p-tabPanel header="Base de Conhecimento IA">
        <app-base-conhecimento-ia></app-base-conhecimento-ia>
    </p-tabPanel>
</p-tabView>